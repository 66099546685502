import { render, staticRenderFns } from "./UserTransactionsListPage.vue?vue&type=template&id=5201b664&scoped=true"
import script from "./UserTransactionsListPage.vue?vue&type=script&lang=js"
export * from "./UserTransactionsListPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5201b664",
  null
  
)

export default component.exports