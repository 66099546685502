<template>
  <gen-view-field-wrapper>
    <span>{{ value |formatDateTime }}</span>
  </gen-view-field-wrapper>
</template>

<script>
import {formFieldViewMixin} from "@/mixins/common";
import GenViewFieldWrapper from "@/components/common/forms/view_fields/GenViewFieldWrapper";

export default {
  name: "GenDatetimeViewField",
  components: {GenViewFieldWrapper},
  mixins: [
    formFieldViewMixin
  ],
  computed: {
    // visibleDateTime: function () {
    //   return moment(this.value).format()
    // }
  }
}
</script>

<style scoped>

</style>