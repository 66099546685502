<template>
  <div>
    <v-row :dense="false" align="stretch">
      <v-col :sm="SM" :md="MD" :lg="LG"
             v-for="(item, ind) in dataItems"
             :key="`item__${ind}`"
      >
        <slot :item="item">
          <div>{{ item }}</div>
        </slot>
      </v-col>
    </v-row>
    <list-pagination-control-component
        :value="currentPageNumber$"
        :total="pagination$.allCount"
        @input="onUiPageChanged"
        :length="paginationLen$"
    />
  </div>
</template>

<script>
import {flexSizeMixin, listViewMixin} from "@/mixins/common";
import ListPaginationControlComponent from "@/components/common/widgets/ListPaginationControlComponent";

export default {
  name: "CardListViewTemplate",
  components: {ListPaginationControlComponent},
  mixins: [
    flexSizeMixin,
    listViewMixin
  ],
  props: {
    dense: {
      type: Boolean,
      default: false
    },
    getDataUrl: {
      type: [String, Function],
      default: undefined
    }
  },
  data() {
    return {
      dataItems: [],
      useQueryOrdering: false
    }
  },
  methods: {
    dataLoaded() {
      this.$emit('data-loaded')
    },
    async getData() {
      let resp = await this.$http.post(
          this.getDataUrl(
              this.pagination$.limit,
              this.pagination$.offset,
              this.orderField || null
          ),
          {},
          {
            changeState: true,
            updatePagination: true,
            rootProgress: true,
          }
      )
      this.dataItems = resp.results
      this.dataLoaded()
      console.log(resp)
    }
  }
}
</script>

<style scoped>

</style>