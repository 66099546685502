<template>
  <page-wrapper :title="pageTitle">
    <div class="d-flex align-center">
      <v-spacer/>
      <v-btn outlined @click="onUploadButtonClick">
        <v-icon left>ri-upload-2-line</v-icon>
        Upload new
      </v-btn>
    </div>
    <div class="mt-3">
      <members-table-view
          @row-click="onRowClick"
          :table-headers="tableHeaders"
          :url-func="getDataQuery"
      >
        <template v-slot:[`prop__user`]="{value}">
          <a :href="`mailto:${value.email}`" v-if="value">{{ value.email }}</a><span v-else>-</span>
        </template>
        <template v-slot:[`prop__ready_at`]="{value}">
          <span v-if="value">{{ value|formatDateTime }}</span><span v-else>-</span>
        </template>
        <template v-slot:[`prop__from_file`]="{value}">
          <span>
            {{ value.name }} ({{ value.size|prettyBytes }})
          </span>
        </template>
      </members-table-view>
    </div>
  </page-wrapper>
</template>

<script>
import PageWrapper from "@/components/common/widgets/PageWrapper";
import {pageTitleMixin} from "@/mixins/common";
import {FieldType} from "@/forms/fieldTypes";
import MembersTableView from "@/components/staff/forms/views/shared/MembersTableView.vue";
import endpoints from "@/utils/web/endpoints";

export default {
  name: "TransactionsListPage",
  components: {MembersTableView, PageWrapper},
  mixins: [
    pageTitleMixin
  ],
  computed: {
    pageTitle: function () {
      return this.$t('staffs.menu.income_payments')
    },
    tableHeaders: function () {
      return {
        user: {
          type: FieldType.TEXT,
          localeLabel: "staffs.income_payment.fields.user"
        },
        from_file: {
          type: FieldType.TEXT,
          localeLabel: "staffs.income_payment.fields.from_file"
        },
        status: {
          type: FieldType.TEXT,
          localeLabel: "staffs.income_payment.fields.status"
        },
        // updated_at: {
        //   type: FieldType.DATETIME,
        //   localeLabel: "staffs.transactions.fields.updated_at"
        // },
        ready_at: {
          type: FieldType.DATETIME,
          localeLabel: 'staffs.income_payment.fields.ready_at'
        },
        created_at: {
          type: FieldType.DATETIME,
          localeLabel: 'common.fields.created_at'
        }
      }
    },
  },
  methods: {
    onUploadButtonClick() {
      this.$router.push({name: 'upload_income'})
    },
    onUserClick(obj) {
      let userRoute = this.getUserRouterLink(obj)
      // console.log(obj, userRoute)
      this.$router.push(userRoute)
    },
    onRowClick(row) {
      // this.$http.post(
      //     endpoints.TRANSFER().STAFF().INCOME_COMPARE_TASK('start', row.id)
      // ).then(resp => {
      //   console.log(resp)
      // })
      this.$router.push({name: 'income_info', params: {income_id: row.id}})
      // console.log(row)
    },
    getDataQuery(limit, offset, order) {
      return endpoints.withQuery(
          endpoints.TRANSFER().STAFF().INCOME_FILE_LIST,
          {
            limit: limit,
            offset: offset,
            sort: order
          }
      )
    },
  }
}
</script>

<style scoped>

</style>