<template>
  <div>
    <v-card @click.prevent="onCardClick">
      <user-verification-status-view :verification-status="input" reverse/>
    </v-card>
    <v-dialog v-model="dialog" light max-width="400">
      <v-card>
        <v-card-title>
          Change status
        </v-card-title>
        <v-card-text class="pb-0">
          <v-radio-group v-model="verificationStatus" hide-details class="mt-0">
            <v-radio
                v-for="status in statusList" :key="`status__${status.value}`"
                :value="status.value"
                :color="status.color"
                :label="status.label"
            >

            </v-radio>
          </v-radio-group>

          <user-verification-status-view
              show-hint class="rounded-sm mt-3"
              :verification-status="verificationStatus"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="grey" @click="dialog = false">
            {{ $t('common.cancel') }}
          </v-btn>
          <v-btn text color="warning" @click="updateUserStatus">
            {{ $t('userActions.save') }}
            <v-icon right>ri-save-line</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UserVerificationStatusView from "@/components/common/widgets/UserVerificationStatusView.vue";
import {modelMixin} from "@/mixins/common";
import endpoints from "@/utils/web/endpoints";

export default {
  name: "ChangeVerificationStatusView",
  components: {UserVerificationStatusView},
  mixins: [modelMixin('input', String, undefined)],
  props: {
    isCompany: {
      type: Boolean,
      default: false
    },
    userId: {
      type: String
    }
  },
  computed: {
    statusList: function () {
      return [
        {
          value: 'verified',
          icon: 'ri-check-line',
          label: this.$t('verificationStatus.verified'),
          color: 'green'
        },
        {
          value: 'blocked',
          icon: 'ri-compass-4-line',
          label: this.$t('verificationStatus.blocked'),
          color: 'orange'
        },
        {
          value: 'full_blocked',
          icon: 'ri-close-line',
          label: this.$t('verificationStatus.full_blocked'),
          color: 'red'
        },
        {
          value: null,
          icon: 'ri-question-line',
          label: this.$t('verificationStatus.unset'),
          color: 'blue'
        },
      ]
    }
  },
  data() {
    return {
      dialog: false,
      verificationStatus: this.input || null
    }
  },
  methods: {
    onCardClick() {
      this.dialog = true
    },
    updateUserStatus() {
      this.$http.post(
          this.isCompany ?
              endpoints.COMPANIES().STAFF_STATUS_SET(this.userId) :
              endpoints.PERSONS().STAFF_STATUS_SET(this.userId),
          {
            verification_status: this.verificationStatus
          }
      ).then(() => {
        this.input = this.verificationStatus
        this.dialog = false
      })
    }
  },
  watch: {
    input: function (value) {
      this.verificationStatus = value || null
    }
  }
}
</script>

<style scoped>

</style>