<template>
  <div>
    <component :is="mountComponent$"
               v-bind="$props"
    >

    </component>
  </div>
</template>

<script>
import {FieldType} from "@/forms/fieldTypes";
import GenRawViewField from "@/components/common/forms/view_fields/GenRawViewField";
import {formFieldViewMixin} from "@/mixins/common";
import GenBoolViewField from "@/components/common/forms/view_fields/GenBoolViewField";
import GenPhoneViewField from "@/components/common/forms/view_fields/GenPhoneViewField";
import GenEmailViewField from "@/components/common/forms/view_fields/GenEmailViewField";
import GenDatetimeViewField from "@/components/common/forms/view_fields/GenDatetimeViewField";

const COMPONENT_MAPPING = {
  [FieldType.BOOL]: GenBoolViewField,
  [FieldType.TEXT]: GenRawViewField,
  [FieldType.FLOAT]: GenRawViewField,
  [FieldType.TEXT_NUMBER]: GenRawViewField,
  [FieldType.LONGTEXT]: GenRawViewField,
  [FieldType.DATE]: GenRawViewField,
  [FieldType.DATETIME]: GenDatetimeViewField,
  [FieldType.PHONE]: GenPhoneViewField,
  [FieldType.EMAIL]: GenEmailViewField,
  [FieldType.SELECT]: GenRawViewField,
  [FieldType.SELECT_RADIO]: GenRawViewField,
  [FieldType.OBJECT_LIST]: GenRawViewField,
  [FieldType.VIEW]: GenRawViewField,
  [FieldType.FILE]: GenRawViewField,
  [FieldType.FILE_BASE_64]: GenRawViewField,
  [FieldType.ADDRESS]: GenRawViewField,
  [FieldType.COUNTRY_SELECT]: GenRawViewField,
  [FieldType.OTP]: GenRawViewField,
}
export default {
  name: "GenViewFieldComponent",
  mixins: [
    formFieldViewMixin
  ],
  computed: {
    mountComponent$: function () {
      return this.getMountComponent()
    }
  },
  methods: {
    getMountComponent() {
      if (!this.type || !COMPONENT_MAPPING[this.type]) {
        return GenRawViewField
      }
      return COMPONENT_MAPPING[this.type]
    }
  }
}
</script>

<style scoped>

</style>