<template>
  <page-wrapper :title="pageTitle">
    <members-table-view
        @row-click="onRowClick"
        :table-headers="tableHeaders"
        :initial-filter="initialFilter"
        :url-func="getDataQuery"
        use-filter filter-use-query
        search-use-query
        :filter-form="filterForm"
        use-search
    >
      <template v-slot:[`prop__amount`]="{value, object}">
        <span v-if="value">{{ value }} {{ object.currency }}</span><span v-else>-</span>
      </template>
      <template v-slot:[`prop__sell_amount`]="{value, object}">
        <span v-if="value">{{ value }} {{ object.sell_currency }}</span><span v-else>-</span>
      </template>
      <template v-slot:[`prop__buy_amount`]="{value, object}">
        <span v-if="value">{{ value }} {{ object.buy_currency }}</span><span v-else>-</span>
      </template>
      <template v-slot:[`prop__fee_amount`]="{value, object}">
        <span v-if="value">{{ value }} {{ object.fee_currency }}</span><span v-else>-</span>
      </template>
      <template v-slot:[`prop__local_status`]="{value}">
        <div class="d-flex align-center text-no-wrap">
          <v-icon :color="getStatusColor(value)">{{ getStatusIcon(value) }}</v-icon>
          <div class="pl-2">{{ $t(`client.payment_status.${value}`) }}</div>
        </div>
      </template>
    </members-table-view>
  </page-wrapper>
</template>

<script>
import PageWrapper from "@/components/common/widgets/PageWrapper";
import {pageTitleMixin} from "@/mixins/common";
import {FieldType} from "@/forms/fieldTypes";
import endpoints from "@/utils/web/endpoints";
import MembersTableView from "@/components/staff/forms/views/shared/MembersTableView";

export default {
  name: "TransactionsListPage",
  components: {MembersTableView, PageWrapper},
  mixins: [
    pageTitleMixin
  ],
  computed: {
    initialFilter: function () {
      return {
        user: this.$route.params?.company_id || this.$route.params?.person_id
      }
    },
    pageTitle: function () {
      return this.$t('staffs.menu.transactions')
    },
    filterForm: function () {
      return {
        local_status: {
          type: FieldType.SELECT,
          localeLabel: 'staffs.transactions.fields.local_status',
          extra: {
            items: [
              {
                value: 'created',
                text: this.$t('client.payment_status.created')
              },
              {
                value: 'payment_created',
                text: this.$t('client.payment_status.payment_created')
              },
              {
                value: 'awaiting_payment',
                text: this.$t('client.payment_status.awaiting_payment')
              },
              {
                value: 'sending_to_receiver',
                text: this.$t('client.payment_status.sending_to_receiver')
              },
              {
                value: 'success',
                text: this.$t('client.payment_status.success')
              },
              {
                value: 'error',
                text: this.$t('client.payment_status.error')
              }
            ]
          }
        },
        amount: {
          localeLabel: 'staffs.transactions.fields.amount',
          type: FieldType.FLOAT,
        },
        amount__gt: {
          label: `${this.$t('staffs.transactions.fields.amount')} (${this.$t('search.num_gt')})`,
          type: FieldType.FLOAT,
        },
        amount__lt: {
          label: `${this.$t('staffs.transactions.fields.amount')} (${this.$t('search.num_lt')})`,
          type: FieldType.FLOAT,
        },
        sell_amount: {
          localeLabel: 'staffs.transactions.fields.sell_amount',
          type: FieldType.FLOAT,
        },
        sell_amount__gt: {
          label: `${this.$t('staffs.transactions.fields.sell_amount')} (${this.$t('search.num_gt')})`,
          type: FieldType.FLOAT,
        },
        sell_amount__lt: {
          label: `${this.$t('staffs.transactions.fields.sell_amount')} (${this.$t('search.num_lt')})`,
          type: FieldType.FLOAT,
        },
        buy_amount: {
          localeLabel: 'staffs.transactions.fields.buy_amount',
          type: FieldType.FLOAT,
        },
        buy_amount__gt: {
          label: `${this.$t('staffs.transactions.fields.buy_amount')} (${this.$t('search.num_gt')})`,
          type: FieldType.FLOAT,
        },
        buy_amount__lt: {
          label: `${this.$t('staffs.transactions.fields.buy_amount')} (${this.$t('search.num_lt')})`,
          type: FieldType.FLOAT,
        },
        fee_amount: {
          localeLabel: 'staffs.transactions.fields.fee_amount',
          type: FieldType.FLOAT,
        },
        fee_amount__gt: {
          label: `${this.$t('staffs.transactions.fields.fee_amount')} (${this.$t('search.num_gt')})`,
          type: FieldType.FLOAT,
        },
        fee_amount__lt: {
          label: `${this.$t('staffs.transactions.fields.fee_amount')} (${this.$t('search.num_lt')})`,
          type: FieldType.FLOAT,
        },
        updated_at__lt: {
          type: FieldType.DATE,
          label: `${this.$t('staffs.transactions.fields.updated_at')} (${this.$t('search.num_lt')})`,
        },
        updated_at__gt: {
          type: FieldType.DATE,
          label: `${this.$t('staffs.transactions.fields.updated_at')} (${this.$t('search.num_gt')})`,
        },
        created_at__lt: {
          type: FieldType.DATETIME,
          label: `${this.$t('staffs.transactions.fields.created_at')} (${this.$t('search.num_lt')})`,
        },
        created_at__gt: {
          type: FieldType.DATETIME,
          label: `${this.$t('staffs.transactions.fields.created_at')} (${this.$t('search.num_gt')})`,
        },
      }
    },
    tableHeaders: function () {
      return {
        id: {
          type: FieldType.TEXT,
          localeLabel: "staffs.transactions.fields.id"
        },
        reference: {
          type: FieldType.TEXT,
          localeLabel: "staffs.transactions.fields.reference"
        },
        // model_cls: {
        //   type: FieldType.TEXT,
        //   localeLabel: "staffs.transactions.fields.model_cls"
        // },
        // name: {
        //   type: FieldType.TEXT,
        //   localeLabel: "staffs.transactions.fields.name"
        // },
        amount: {
          type: FieldType.TEXT_NUMBER,
          localeLabel: "staffs.transactions.fields.amount"
        },
        fee_amount: {
          type: FieldType.TEXT_NUMBER,
          localeLabel: "staffs.transactions.fields.fee_amount"
        },
        sell_amount: {
          type: FieldType.TEXT_NUMBER,
          localeLabel: "staffs.transactions.fields.sell_amount"
        },
        buy_amount: {
          type: FieldType.TEXT_NUMBER,
          localeLabel: "staffs.transactions.fields.buy_amount"
        },
        local_status: {
          type: FieldType.TEXT,
          localeLabel: "staffs.transactions.fields.local_status"
        },
        updated_at: {
          type: FieldType.DATETIME,
          localeLabel: "staffs.transactions.fields.updated_at"
        },
        created_at: {
          type: FieldType.DATETIME,
          localeLabel: 'common.fields.created_at'
        }
      }
    },
  },
  methods: {
    getUserRouterLink(obj) {
      if (obj.model_cls === 'person') return {name: 'staff-person-info-page', params: {person_id: obj.user}}
      if (obj.model_cls === 'company') return {name: 'staff-company-info-page', params: {company_id: obj.user}}
      return undefined
    },
    getStatusIcon(status) {
      if (status === 'success') return 'ri-checkbox-circle-line'
      if (status === 'error') return 'ri-close-circle-line'
      return 'ri-refresh-line'
    },
    getStatusColor(status) {
      if (status === 'success') return 'success'
      if (status === 'error') return 'error'
      return 'primary'
    },
    getDataQuery(limit, offset, order) {
      return endpoints.withQuery(
          endpoints.TRANSFER().STAFF().TRANSACTION_LIST,
          {
            limit: limit,
            offset: offset,
            sort: order
          }
      )
    },
    onRowClick(item) {
      console.log(item)
      // this.$router.push({
      //   name: 'staff-company-info-page',
      //   params: {'company_id': item.id}
      // })
    },
    onUserClick(obj) {
      let userRoute = this.getUserRouterLink(obj)
      // console.log(obj, userRoute)
      this.$router.push(userRoute)
    }
  }
}
</script>

<style scoped>

</style>