<template>
  <page-wrapper ref="scrollPageRoot" style="min-height: calc(100vh - 24px)">
    <v-card v-if="loadingStatus$.loaded">
      <v-card-title class="d-flex align-center">
        <div>{{ pageTitle }}</div>
        <v-spacer/>
        <v-btn color="warning" elevation="0" @click="saveData">
          <v-icon left>ri-save-line</v-icon>
          {{ $t('userActions.save') }}
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-tabs
            ref="tabsHolder"
            v-model="tab"
            bg-color="primary"
        >
          <v-tab :value="0">
            {{ $t("fullRegSteps.general_info") }}
          </v-tab>
          <!--          <v-tab :value="1">-->
          <!--            {{ $t("staffs.company.counters.reg_doc_count") }}-->
          <!--          </v-tab>-->
          <v-tab :value="index + 1" v-for="(item, index) in stepItems" :key="`th__${item.key}_${index + 1}`">
            {{ $t(`fullRegSteps.${item.key}`) }}
          </v-tab>
        </v-tabs>
        <!--        :value="ind < activeWindow"-->
        <!--        :active="ind === activeWindow"-->
      </v-card-text>
    </v-card>
    <v-window v-model="tab">
      <v-window-item :value="0">
        <generic-form :form="generalInfoForm" v-if="input"
                      class="pt-6"
                      v-model="input"/>
      </v-window-item>
      <!--      <v-window-item :value="1">-->

      <!--      </v-window-item>-->
      <v-window-item :value="index + 1" v-for="(item, index) in stepItems" :key="`tw__${item.key}_${index + 1}`">
        <v-container fluid>
          <generic-form v-if="item.form && input" :form="item"
                        :all-form-data="input"
                        :is-valid.sync="validSet[item.key]"
                        v-model="input[item.key]"/>
        </v-container>
      </v-window-item>
    </v-window>
  </page-wrapper>
</template>

<script>
import PageWrapper from "@/components/common/widgets/PageWrapper";
import {autoPageScrollMixin, loadingMixin} from "@/mixins/common";
import full_reg_form from "@/forms/complete/full_reg_form";
import GenericForm from "@/components/common/forms/GenericForm.vue";
// eslint-disable-next-line no-unused-vars
import {initModelSetByFormList, initValidSetByFormList} from "@/forms/formUtils";
import endpoints from "@/utils/web/endpoints";
import {companyForm} from "@/forms/complete/general_info_form";
import {prepareFormData} from "@/utils/forms";
import {FieldType} from "@/forms/fieldTypes";
import {CONSTS} from "@/utils/consts";
// import goTo from "vuetify/lib/services/goto";

export default {
  name: "CompanyFullInfoEditPage",
  components: {GenericForm, PageWrapper},
  mixins: [
    autoPageScrollMixin,
    loadingMixin
  ],
  computed: {
    companyId: function () {
      return this.$route.params.company_id
    },
    excludeFormKeys: function () {
      return ['create_account', 'app_info', 'set_of_docs']
      // return ['create_account', 'app_info']
    },
    stepItems: function () {
      if (this.fullInfoFilled) return [
        ...this.fullForm.filter(item => !this.excludeFormKeys.includes(item.key)),
        {
          key: 'set_of_docs',
          form: [
            {
              label: this.$t('fullRegGroups.set_of_docs'),
              key: 'app_declaration',
              form: [
                {
                  type: FieldType.FILE_BASE_64,
                  key: 'set_of_docs',
                  label: this.$t('common.doc'),
                  extra: {
                    many: true,
                    max: 50,
                    typeTags: CONSTS.companyRegDocTypes.map(tt => ({
                      text: this.$t(`setOfDocsTypes.${tt}`),
                      value: tt
                    })),
                    accept: 'application/pdf,image/jpeg,image/png,image/jpg'
                  }
                },
              ]
            },
          ]
        },
      ]
      return []
    },
    fullForm: function () {
      return full_reg_form(this, 'input', true, true)
    },
    generalInfoForm: function () {
      return companyForm(this)
    },
    pageTitle: function () {
      return this.$t('staffs.company.counters.edit_company_info')
    },
  },
  data() {
    return {
      tab: 0,
      input: undefined,
      validSet: undefined,
      fullInfoFilled: false
    }
  },
  methods: {
    getData() {
      this.$http.get(
          endpoints.COMPANIES().STAFF_INFO_FOR_EDIT(this.companyId),
          {changeState: true}
      ).then(resp => {
        // console.log("C DATA", resp)
        this.fullInfoFilled = resp.full_info_filled
        this.input = resp
      })
    },
    prepareFullForm() {
      let res = {}
      for (let i = 0; i < this.stepItems.length; i++) {
        let formItem = this.stepItems[i]
        if (formItem.form) {
          let prepared = prepareFormData(formItem.form, this.input[formItem.key])
          let _keys = Object.keys(prepared)
          res[formItem.key] = (_keys.length === 1 && _keys[0] === formItem.key) ?
              prepared[formItem.key] : prepared
        }
      }
      return res
    },
    saveData() {
      let registrationData = this.prepareFullForm()
      let generalInfo = prepareFormData(this.generalInfoForm.form, this.input)
      let data = {
        ...generalInfo,
        ...registrationData
      }
      // console.log(data)
      this.$http.post(
          endpoints.COMPANIES().STAFF_INFO_FOR_EDIT_ACCEPT(this.companyId),
          data
      ).then(() => {
        this.$notify({
          group: 'success',
          text: this.$t('common.changes_saved_successfully')
        })
        this.$emit('data-updated')
      })
    }
  },
  watch: {
    // tab: function () {
    //   let tabsHolder = this.$refs.tabsHolder
    //   if (!tabsHolder) return
    //   setTimeout(() => {
    //     goTo(tabsHolder, {
    //       easing: "easeInOutCubic",
    //     })
    //   }, 10)
    // }
  },
  created() {
    // this.input = initModelSetByFormList(this.stepItems)
    this.validSet = initValidSetByFormList(this.stepItems)
    this.getData()
  }
}
</script>

<style scoped>

</style>