<template>
  <v-row dense align-content="start">
    <v-col :sm="SM" :md="MD" :lg="LG"
           class="animated delayed-appear fadeIn"
           v-for="item in countsData" :key="item.key"
    >
      <dash-counter-view
          v-bind="item"
      />
    </v-col>
  </v-row>
</template>

<script>
import endpoints from "@/utils/web/endpoints";
import DashCounterView from "@/components/staff/widgets/dash/DashCounterView";
import {flexSizeMixin} from "@/mixins/common";
import {CONSTS} from "@/utils/consts";


export default {
  name: "DashGeneralCountersView",
  components: {DashCounterView},
  mixins: [
    flexSizeMixin
  ],
  computed: {
    countsData: function () {
      return CONSTS.dashCountsDataMapping.map(item => ({
        key: item.data_key,
        label: this.$t(`staffs.menu.${item.data_key}`),
        color: item.color,
        icon: item.icon,
        to: item.to,
        count: this.dataCounts[item.data_key],
      }))
    }
  },
  data() {
    return {
      dataCounts: {}
    }
  },
  methods: {
    getData() {
      this.$http.get(endpoints.DASH().GENERAL_COUNTS, {
        rootProgress: true
      }).then(resp => {
        this.dataCounts = resp
      })
    }
  },
  created() {
    this.getData()
  }
}
</script>

<style scoped>

</style>