<template>
  <!--  <page-wrapper :title="pageTitle">-->
  <page-wrapper>
    <div v-if="incomeInfo">
      <v-row dense>
        <v-col>
          <gen-file-view-field
              :outlined="false"
              :value="incomeInfo.from_file"
          />
        </v-col>
        <v-col>
          <v-card>
            <v-card-title>
              {{ incomeInfo.items.length }}
            </v-card-title>
            <v-card-subtitle>
              Matches found
            </v-card-subtitle>
          </v-card>
          <v-card class="mt-3">
            <v-card-title>
              {{ incomeInfo.status }}
            </v-card-title>
            <v-card-subtitle>
              Status
            </v-card-subtitle>
          </v-card>
          <v-btn class="mt-3"
                 block x-large color="primary"
                 :disabled="!incomeInfo.items.length || loadingStatus$.loading"
                 @click="applyAll"
          >
            <v-icon>ri-check-double-line</v-icon>
            Apply all
          </v-btn>
        </v-col>
      </v-row>
      <v-card class="mt-3" v-if="incomeInfo.errors">
        <v-card-title>
          Errors
        </v-card-title>
        <v-card-text style="overflow-x: auto">
          <pre style="word-wrap: break-word">{{ JSON.stringify(incomeInfo.errors, undefined, 2) }}</pre>
        </v-card-text>
      </v-card>
      <div class="mt-3">
        <transaction-income-payment-item-view
            :disable-apply="loadingStatus$.loading"
            @status-changed="onStatusChanged($event, item)"
            v-for="(item, ind) in incomeInfo.items"
            :key="`item__${ind}`"
            :item="item"
        />
      </div>
    </div>

  </page-wrapper>
</template>

<script>
import PageWrapper from "@/components/common/widgets/PageWrapper";
import {loadingMixin, pageTitleMixin} from "@/mixins/common";
import {FieldType} from "@/forms/fieldTypes";
import endpoints from "@/utils/web/endpoints";
import GenFileViewField from "@/components/common/forms/view_fields/GenFileViewField.vue";
import TransactionIncomePaymentItemView from "@/components/staff/widgets/payments/TransactionIncomePaymentItemView.vue";

export default {
  name: "TransactionsListPage",
  components: {TransactionIncomePaymentItemView, GenFileViewField, PageWrapper},
  mixins: [
    pageTitleMixin, loadingMixin
  ],
  computed: {
    pageTitle: function () {
      return this.$t('staffs.menu.income_payments')
    },
    tableHeaders: function () {
      return {
        id: {
          type: FieldType.TEXT,
          localeLabel: "staffs.transactions.fields.id"
        },
        model_cls: {
          type: FieldType.TEXT,
          localeLabel: "staffs.transactions.fields.model_cls"
        },
        name: {
          type: FieldType.TEXT,
          localeLabel: "staffs.transactions.fields.name"
        },
        amount: {
          type: FieldType.TEXT_NUMBER,
          localeLabel: "staffs.transactions.fields.amount"
        },
        fee_amount: {
          type: FieldType.TEXT_NUMBER,
          localeLabel: "staffs.transactions.fields.fee_amount"
        },
        sell_amount: {
          type: FieldType.TEXT_NUMBER,
          localeLabel: "staffs.transactions.fields.sell_amount"
        },
        buy_amount: {
          type: FieldType.TEXT_NUMBER,
          localeLabel: "staffs.transactions.fields.buy_amount"
        },
        local_status: {
          type: FieldType.TEXT,
          localeLabel: "staffs.transactions.fields.local_status"
        },
        updated_at: {
          type: FieldType.DATETIME,
          localeLabel: "staffs.transactions.fields.updated_at"
        },
        created_at: {
          type: FieldType.DATETIME,
          localeLabel: 'common.fields.created_at'
        }
      }
    },
  },
  data() {
    return {
      incomeInfo: undefined
    }
  },
  methods: {
    onStatusChanged(status, item) {
      this.$set(item, 'local_status', status)
      // item.local_status = status
    },
    getIncomePaymentDataInfo() {
      this.$http.get(
          endpoints.TRANSFER().STAFF().INCOME_DATA_INFO(this.$route.params.income_id)
      ).then(resp => {
        this.incomeInfo = resp
      })
    },
    onUploadButtonClick() {
      this.$router.push({name: 'upload_income'})
    },
    onUserClick(obj) {
      let userRoute = this.getUserRouterLink(obj)
      // console.log(obj, userRoute)
      this.$router.push(userRoute)
    },
    applyAll() {
      let ok = confirm("Are you sure you want to confirm all payments?")
      if (!ok) return
      this.$http.post(
          endpoints.TRANSFER().STAFF().APPLY_ALL(this.incomeInfo.id)
      ).then(resp => {
        console.log(resp)
      })
    },
    onPaymentProcessDone() {
      // console.log("on done")
      this.getIncomePaymentDataInfo()
    },
  },
  created() {
    this.getIncomePaymentDataInfo()
    this.$bus?.$on('ws-income_payment_process_done', this.onPaymentProcessDone)
  },
  destroyed() {
    this.$bus?.$on('ws-income_payment_process_done', this.onPaymentProcessDone)
  }
}
</script>

<style scoped>

</style>