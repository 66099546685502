import LogoutPage from "@/components/common/pages/LogoutPage";
import SignInPage from "@/components/staff/pages/SignInPage";
import MainLayout from "@/components/staff/layouts/MainLayout";
import NotFound from "@/components/common/pages/NotFound";
import DashboardPage from "@/components/staff/pages/DashboardPage";
import CompaniesListPage from "@/components/staff/pages/CompaniesListPage";
import CompanyFullInfoPage from "@/components/staff/pages/CompanyFullInfoPage/CompanyFullInfoPage";
import CompanyRegDocsPage from "@/components/staff/pages/CompanyFullInfoPage/CompanyRegDocsPage";
import CompanyUploadedFilesPage from "@/components/staff/pages/CompanyFullInfoPage/CompanyUploadedFilesPage";
import PersonsListPage from "@/components/staff/pages/PersonsListPage";
import PersonFullInfoPage from "@/components/staff/pages/PersonFullInfoPage/PersonFullInfoPage";
import CompanyFullInfoEditPage from "@/components/staff/pages/CompanyFullInfoPage/CompanyFullInfoEditPage.vue";
import AppPreferencesPage from "@/components/staff/pages/AppPreferencesPage.vue";
import PersonFullInfoEditPage from "@/components/staff/pages/PersonFullInfoPage/PersonFullInfoEditPage.vue";
import TransactionsListPage from "@/components/staff/pages/TransactionsListPage.vue";
import IncomePaymentListPage from "@/components/staff/pages/IncomePaymentListPage.vue";
import UploadIncomePaymentsPage from "@/components/staff/pages/UploadIncomePaymentsPage.vue";
import IncomePaymentItemPage from "@/components/staff/pages/IncomePaymentItemPage.vue";
import UserTransactionsListPage from "@/components/staff/pages/UserTransactionsListPage.vue";
import CompanyEditInfoHistoryPage from "@/components/staff/pages/CompanyFullInfoPage/CompanyEditInfoHistoryPage.vue";

export default [
  {
    path: '/login',
    name: 'login',
    redirect: {name: 'signin'}
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutPage
  },
  {
    path: '/signin',
    name: 'signin',
    component: SignInPage,
  },
  {
    path: '/',
    component: MainLayout,
    name: "main-layout",
    children: [
      {
        path: '/dash',
        name: 'dashboard',
        component: DashboardPage,
      },
      {
        path: '/prefs',
        name: 'app_preferences',
        component: AppPreferencesPage,
      },
      {
        path: '/income',
        name: 'income_payments',
        component: IncomePaymentListPage,
      },
      {
        path: '/income/info/:income_id',
        name: 'income_info',
        component: IncomePaymentItemPage,
      },
      {
        path: '/income/upload',
        name: 'upload_income',
        component: UploadIncomePaymentsPage,
      },
      {
        path: '/companies',
        name: 'staff-companies-list',
        component: CompaniesListPage,
      },
      {
        path: '/persons',
        name: 'staff-persons-list',
        component: PersonsListPage,
      },
      {
        path: '/persons/:person_id',
        name: 'staff-person-info-page',
        component: PersonFullInfoPage,
        children: [
          {
            path: '/persons/:person_id/transactions',
            name: 'staff-person-transactions-page',
            component: UserTransactionsListPage,
          },
          {
            path: '/persons/:person_id/edit_info',
            name: 'staff-person-edit-info-page',
            component: PersonFullInfoEditPage,
          }
        ]
      },
      {
        path: '/companies/:company_id',
        name: 'staff-company-info-page',
        component: CompanyFullInfoPage,
        children: [
          // staff-company-edit-info-page
          {
            path: '/companies/:company_id/reg_docs',
            name: 'staff-company-info-docs-page',
            component: CompanyRegDocsPage,
          },
          {
            path: '/companies/:company_id/files',
            name: 'staff-company-uploaded-files-page',
            component: CompanyUploadedFilesPage,
          },
          {
            path: '/companies/:company_id/transactions',
            name: 'staff-company-transactions-page',
            component: UserTransactionsListPage,
          },
          {
            path: '/companies/:company_id/edit_info',
            name: 'staff-company-edit-info-page',
            component: CompanyFullInfoEditPage,
          },
          {
            path: '/companies/:company_id/edit_info_history',
            name: 'staff-company-edit-info-history-page',
            component: CompanyEditInfoHistoryPage,
          }
        ]
      },
      {
        path: '/transactions',
        name: 'staff-transactions-list',
        component: TransactionsListPage,
      },
      {
        path: '*',
        component: NotFound,
        props: {toolbar: true}
      }
    ]
  },
]