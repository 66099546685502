<template>
  <page-wrapper ref="scrollPageRoot">
    <v-card>
      <v-card-title>
        {{ pageTitle }}
      </v-card-title>
      <v-card-text>
        <card-list-view-template
            :get-data-url="queryFunc"
            @data-loaded="scrollToTop"
        >
          <template v-slot:default="{item}">
            <gen-file-view-field
                :doc-type-labels="docTypesLabels"
                :value="item"
            />
          </template>
        </card-list-view-template>
      </v-card-text>
    </v-card>
  </page-wrapper>
</template>

<script>
import PageWrapper from "@/components/common/widgets/PageWrapper";
import {autoPageScrollMixin} from "@/mixins/common";
import CardListViewTemplate from "@/components/common/forms/helpers/CardListViewTemplate";
import endpoints from "@/utils/web/endpoints";
import GenFileViewField from "@/components/common/forms/view_fields/GenFileViewField";
import {CONSTS} from "@/utils/consts";

export default {
  name: "CompanyRegDocsPage",
  components: {GenFileViewField, CardListViewTemplate, PageWrapper},
  mixins: [
    autoPageScrollMixin,
  ],
  computed: {
    pageTitle: function () {
      return this.$t('staffs.company.counters.reg_doc_count')
    },
    docTypesLabels: function () {
      return CONSTS.companyRegDocTypes.reduce((previousValue, currentValue) => {
        previousValue[currentValue] = this.$t(`setOfDocsTypes.${currentValue}`)
        return previousValue
      }, {})
    }
  },
  data() {
    return {
      queryFunc: (limit, offset, sort) => endpoints.withQuery(
          endpoints.COMPANIES().STAFF_DOCS(this.$route.params.company_id),
          {
            limit: limit,
            offset: offset,
            sort: sort
          }
      ),
    }
  }
}
</script>

<style scoped>

</style>