<template>
  <div class="d-flex align-center fw">
    <div v-html="$t('pagination.totalN', {n: total})"></div>
    <v-spacer></v-spacer>
    <v-pagination
        light circle
        :value="value"
        @input="onUiPageChanged"
        :length="length"
        :total-visible="visiblePages"
    ></v-pagination>
  </div>
</template>

<script>
export default {
  name: "ListPaginationControlComponent",
  props: {
    value: {
      type: Number
    },
    length: {
      type: Number,
    },
    total: {
      type: Number,
      default: 0
    },
    visiblePages: {
      type: Number,
      default: 5,
    }
  },
  methods: {
    onUiPageChanged(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped>

</style>