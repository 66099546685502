<template>
  <gen-view-field-wrapper class="fh">
    <div v-if="simple">
      <span v-if="value">{{ value.name }}</span>
      <span v-else>No file presented</span>
    </div>
    <div v-else class="fh">
      <v-card v-if="value"
              min-width="200"
              class="fh d-flex flex-column" :outlined="outlined">
        <v-card-text class="d-flex align-center justify-center">
          <file-card-image :mimetype="value.type"/>
          <!--          <v-icon-->
          <!--              size="80" :color="color">description-->
          <!--          </v-icon>-->
        </v-card-text>
        <v-card-subtitle
            v-if="docTypeLabel"
            class="caption text-center fw d-block py-0">
          {{ docTypeLabel }}
        </v-card-subtitle>
        <v-card-title class="body-2 text-center fw fh d-block pt-0">
          {{ value.name }}
        </v-card-title>
        <v-card-subtitle class="caption text-center fw d-block py-0">
          {{ value.size |prettyBytes }}
          <br>
          {{ value.created_at |formatDateTime }}
        </v-card-subtitle>
        <v-card-actions v-if="!noDownload">
          <v-btn class="fw" @click="downloadFile">
            <v-icon left>ri-download-line</v-icon>
            {{ $t('userActions.download') }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else min-width="200"
              class="fh d-flex flex-column" outlined>
        <v-card-text class="d-flex align-center justify-center">
          <v-icon size="80" color="secondary">
            ri-file-damage-line
          </v-icon>
        </v-card-text>
        <div class="d-flex align-center justify-center fh">
          <div class="text-center fw">
            No file presented
          </div>
        </div>
      </v-card>
    </div>
  </gen-view-field-wrapper>
</template>

<script>
import {formFieldViewMixin} from "@/mixins/common";
import GenViewFieldWrapper from "@/components/common/forms/view_fields/GenViewFieldWrapper";
import endpoints from "@/utils/web/endpoints";
import FileCardImage from "@/components/common/forms/helpers/FileCardImage";

export default {
  name: "GenFileViewField",
  components: {FileCardImage, GenViewFieldWrapper},
  mixins: [
    formFieldViewMixin
  ],
  computed: {
    docTypeLabel: function () {
      let type = this.value?.document_type
      if (!type)
        return undefined
      if (!this.docTypeLabels) {
        return type
      }
      return this.docTypeLabels[type] || type
    }
  },
  props: {
    outlined: {
      type: Boolean,
      default: true
    },
    noDownload: {
      type: Boolean,
      default: false
    },
    docTypeLabels: {
      type: Object,
      default: undefined
    }
  },
  methods: {
    downloadFile() {
      this.$http.download(
          endpoints.STORAGE().DOWNLOAD(this.value?.id),
          {
            downloadFileName: this.value?.name,
            rootProgress: true
          }
      )
    }
  }
}
</script>

<style scoped>

</style>