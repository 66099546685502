<template>
  <div ref="chartContainer" style="height: 50vh; width: 100%"></div>
</template>

<script>
export default {
  name: "CountryStatDataChart",
  props: {
    value: {
      type: Array,
      default: undefined
    }
  },
  computed: {
    container: function () {
      return this.$refs?.chartContainer
    },
    // chartColorRange: function () {
    //   return
    // }
    chartScales: function () {
      let scale = window.anychart.scales.ordinalColor([
        {less: 10},
        {from: 10, to: 30},
        {from: 30, to: 50},
        {from: 50, to: 100},
        {from: 100, to: 200},
        {from: 200, to: 300},
        {from: 300, to: 500},
        {from: 500, to: 1000},
        {greater: 1000}
      ])
      scale.colors([
            '#eceff1',
            '#cfd8dc',
            '#b0bec5',
            '#90a4ae',
            '#78909c',
            '#607d8b',
            '#546e7a',
            '#455a64',
            '#37474f'
          ]);
      // scale.colors([
      //   '#dcedc8',
      //   '#c5e1a5',
      //   '#aed581',
      //   '#9ccc65',
      //   '#8bc34a',
      //   '#7cb342',
      //   '#689f38',
      //   '#558b2f',
      //   '#33691e'
      // ])
      // scale.colors([
      //   '#dcedc8',
      //   '#c5e1a5',
      //   '#aed581',
      //   '#9ccc65',
      //   '#8bc34a',
      //   '#7cb342',
      //   '#689f38',
      //   '#558b2f',
      //   '#33691e'
      // ])
      return scale
    }
  },
  methods: {
    getChartColorRange(map) {
      let colorRange = map.colorRange();
      colorRange.enabled(true).padding([0, 0, 20, 0]);
      colorRange
          .ticks()
          .enabled(true)
          // .stroke('3 #ffffff')
          .position('center')
          .length(7);
      colorRange.colorLineSize(8);
      colorRange.marker().fill(this.$vuetify.theme.themes.dark.primary).size(7);
      colorRange
          .labels()
          .fontSize(8)
          .padding(3, 0, 0, 0)
          .format(function () {
            let range = this.colorRange;
            let name;
            if (isFinite(range.start + range.end)) {
              name = range.start + ' - ' + range.end;
            } else if (isFinite(range.start)) {
              name = 'More than ' + range.start;
            } else {
              name = 'Less than ' + range.end;
            }
            return name;
          });
      return colorRange
    },
    initChart() {
      console.log("CHART", window.anychart)
      window.anychart.theme('darkEarth')
      let map = window.anychart.map()
      map.geoData(window.anychart.maps.world)
      // map.credits().enabled(true).text('lol')
      map.padding([20, 20, 20, 20])
      map.interactivity().selectionMode('none')
      let series = map.choropleth(this.value)
      series.labels(false)
      series
          .hovered()
          .fill(this.$vuetify.theme.themes.dark.primary)
          .stroke(window.anychart.color.darken(this.$vuetify.theme.themes.dark.primary))

      this.getChartColorRange(map)
      series.colorScale(this.chartScales)
      map.container(this.container)
      map.draw()
    }
  },
  mounted() {
    this.initChart()
  }
}
</script>

<style scoped>

</style>