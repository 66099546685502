<template>
  <div>
    <v-card>
      <v-form
          class="pa-2 d-flex align-center"
          @submit.prevent="onSearchSubmit"
      >
        <template v-if="useSearch">
          <v-text-field
              style="max-width: 300px"
              v-model="searchString"
              rounded filled dense hide-details
              :placeholder="$t('search.label')"
          >

          </v-text-field>
          <v-btn icon class="ml-1" type="submit">
            <v-icon>ri-search-line</v-icon>
          </v-btn>
          <v-spacer/>
        </template>
        <v-btn
            text color="primary"
            @click.prevent="showFilter = !showFilter" v-if="useFilter && filterForm"
        >
          <v-icon left>ri-filter-line</v-icon>
          {{ $t('search.filter') }}
        </v-btn>
        <list-export-dialog @on-export="onExportData"/>
      </v-form>
      <v-expand-transition v-if="useFilter && filterForm">
        <div v-show="showFilter">
          <table-filter-view
              class="ma-2"
              :filter-form="filterForm"
              :filter-count.sync="filterCount"
              :use-query-string="filterUseQuery"
              @submit="onFilterSubmit"
              v-model="filterData"
          />
        </div>
      </v-expand-transition>
      <v-simple-table>
        <thead>
        <tr>
          <th>#</th>
          <th v-for="header in tableHeaderList"
              style="min-width: 150px"
              v-ripple="{ class: `primary--text` }"
              @click="headerClick(header)"
              :key="`th__${header.key}`">
            <div class="d-flex align-center">
              <v-expand-x-transition mode="out-in">
              <span v-if="orderData$.field === header.key">
                <v-icon left class="table-order-icon"
                        :class="{'rotated': orderData$.isUp}">ri-arrow-up-circle-line</v-icon>
              </span>
              </v-expand-x-transition>
              <span>{{ header.label }}</span>
            </div>
            <!--            arrow_downward-->
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-ripple="{ class: `primary--text` }"
            @click="rowClick(item, rowIndex)"
            v-for="(item, rowIndex) in dataItems" :key="`data_item__${rowIndex}`">
          <th class="text-no-wrap">
            {{ rowIndex + 1 }}
          </th>
          <td v-for="field in tableHeaderList" :key="`data_item__${rowIndex}_${field.key}`">
            <slot
                :name="`prop__${field.key}`"
                :value="item[field.key]"
                :object="item"
            >
              <gen-view-field-component
                  :data-key="field.key"
                  :type="field.type"
                  :value="item[field.key]"
              />
            </slot>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
      <v-card-actions>
        <list-pagination-control-component
            :value="currentPageNumber$"
            :total="pagination$.allCount"
            @input="onUiPageChanged"
            :length="paginationLen$"
        />
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {tableHeadersToList} from "@/helpers/js_utils";
import {listViewMixin} from "@/mixins/common";
import ListPaginationControlComponent from "@/components/common/widgets/ListPaginationControlComponent";
import GenViewFieldComponent from "@/components/common/forms/view_fields/GenViewFieldComponent";
import TableFilterView from "@/components/staff/forms/views/shared/TableFilterView.vue";
import {applyRouterQuery} from "@/plugins/router";
import ListExportDialog from "@/components/common/widgets/ListExportDialog.vue";

export default {
  name: "MembersTableView",
  components: {ListExportDialog, TableFilterView, GenViewFieldComponent, ListPaginationControlComponent},
  mixins: [
    listViewMixin
  ],
  props: {
    initialFilter: {
      type: Object,
      default: () => ({}),
    },
    filterForm: {
      type: Object,
      default: undefined
    },
    filterUseQuery: {
      type: Boolean,
      default: false
    },
    searchUseQuery: {
      type: Boolean,
      default: false
    },
    tableHeaders: {
      type: Object
    },
    urlFunc: {
      type: Function
    },
    useSearch: {
      type: Boolean,
      default: false
    },
    useFilter: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tableHeaderList: function () {
      return tableHeadersToList(this, this.tableHeaders)
    }
  },
  data() {
    return {
      searchString: '',
      showFilter: false,
      filterCount: 0,
      filterData: {},
      dataItems: []
    }
  },
  methods: {
    onSearchSubmit() {
      if (this.searchUseQuery) {
        this._setQuerySearch(this.searchString)
      }
      this.getData()
    },
    headerClick(header) {
      if (this.orderData$.field === header.key) {
        this.orderField = ((this.orderField === header.key) ? '-' : '') + header.key
      } else {
        this.orderField = header.key
      }
    },
    // eslint-disable-next-line no-unused-vars
    rowClick(item, index) {
      this.$emit('row-click', item, index)
    },
    onFilterSubmit(filterData) {
      this.filterData = filterData
      this.getData()
    },
    async getData() {
      let resp = await this.$http.post(
          this.urlFunc(
              this.pagination$.limit,
              this.pagination$.offset,
              this.orderField || null
          ),
          {
            search: this.searchString,
            filter: {
              ...this.initialFilter,
              ...(this.filterData || {})
            }
          },
          {
            changeState: true,
            updatePagination: true,
            rootProgress: true,
          }
      )
      this.dataItems = resp.results
      // console.log(resp)
    },
    _getQuerySearch() {
      return this.$route.query?.search || ''
    },
    _setQuerySearch(searchString) {
      let querySearch = this.$route.query?.search
      if (searchString !== querySearch) {
        return applyRouterQuery(this, {
          search: searchString
        }, false)
      }
    },
    onExportData(exportParams) {
      this.$http.post(
          this.urlFunc(
              this.pagination$.limit,
              this.pagination$.offset,
              this.orderField || null
          ),
          {
            search: exportParams.useFilter ? this.searchString : undefined,
            filter: exportParams.useFilter ? {
              ...this.initialFilter,
              ...(this.filterData || {})
            } : this.initialFilter,
            export: exportParams.type,
          },
          {
            rootProgress: true,
            downloadFile: true
          }
      ).then(resp => {
        console.log('export resp', resp)
      })
    },
    beforeGetData() {
      if (this.searchUseQuery) {
        this.searchString = this._getQuerySearch()
      }
    }
  },
}
</script>

<style scoped>
.table-order-icon {
  transition: all 0.1s linear;
}

.table-order-icon.rotated {
  transform: rotate(180deg);
}
</style>