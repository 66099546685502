<template>
  <v-card>
    <v-card-title>
      <span>{{ item.amount }} {{ item.currency }}</span>
      <!--      <span class="text&#45;&#45;secondary">{{ $t(`client.payment_status.${item.local_status}`) }}</span>-->
    </v-card-title>
    <v-card-subtitle class="pb-0">
      {{ item.id }}
    </v-card-subtitle>
    <v-card-subtitle class="pt-0">
      {{ $t(`client.payment_status.${item.local_status}`) }}
    </v-card-subtitle>
    <v-card-text>
      <table class="fw">
        <tr class="text-left">
          <th></th>
          <th>In</th>
          <th>Out</th>
        </tr>
        <tr v-for="(row, ind) in infoRows" :key="`row__${ind}`">
          <td>
            <v-icon>{{ row.icon }}</v-icon>
          </td>
          <td>
            {{ getValue(row, row.left) }}
          </td>
          <td>
            {{ getValue(row, row.right) }}
          </td>
        </tr>
      </table>
      <!--      <div v-for="(row, ind) in infoRows" :key="`row__${ind}`">-->
      <!--        <div></div>-->
      <!--      </div>-->
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="primary"
             :disabled="isDisabled"
             @click="applyTransaction"
      >
        <v-icon left>ri-checkbox-circle-line</v-icon>
        Apply
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {getDotted} from "@/helpers/js_utils";
import endpoints from "@/utils/web/endpoints";

export default {
  name: "TransactionIncomePaymentItemView",
  props: {
    item: {
      type: Object
    },
    disableApply: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isDisabled: function () {
      return this.disableApply || !['awaiting_payment', 'error'].includes(this.item.local_status)
    },
    infoRows: function () {
      return [
        {
          icon: 'ri-user-line',
          left: 'accepted_by_payment.beneficiary_name',
          right: 'name',
        },
        {
          icon: 'ri-calendar-line',
          left: 'accepted_by_payment.transaction_date',
          right: 'created_at',
          filter: this.$options.filters.formatDateTime
        },
        {
          icon: 'ri-text',
          left: 'accepted_by_payment.reference',
          right: 'reference',
        },
      ]
    }
  },
  methods: {
    getValue(row, keyOrGetter) {
      if (typeof keyOrGetter === "string") {
        if (row.filter)
          return row.filter(getDotted(this.item, keyOrGetter))
        return getDotted(this.item, keyOrGetter)
      } else if (typeof keyOrGetter === "function") {
        return keyOrGetter()
      }
    },
    applyTransaction() {
      this.$http.post(
          endpoints.TRANSFER().STAFF().TRANSACTION_APPLY(this.item.id)
      ).then(resp => {
        console.log(resp)
      })
    },
    onPaymentError() {
      // this.$notifications.notifyPaymentApproveError(this, data.payment, data.message)
      this.$emit('status-changed', 'error')
    },
    onPaymentDone() {
      this.$emit('status-changed', 'success')
      // this.$notifications.notifyPaymentApproveError(this, data.payment)
    },
  },
  created() {
    this.$bus?.$on('ws-transfer_payment_error', this.onPaymentError)
    this.$bus?.$on('ws-transfer_payment_done', this.onPaymentDone)
  },
  destroyed() {
    this.$bus?.$off('ws-transfer_payment_error', this.onPaymentError)
    this.$bus?.$off('ws-transfer_payment_done', this.onPaymentDone)
  }
}
</script>

<style scoped>

</style>