<template>
  <div
      class="d-flex align-center justify-center py-3"
      :class="{'im-bg': !noBg}"
  >
    <v-img :src="source"
           :height="size"
           contain>
    </v-img>
  </div>

</template>

<script>

import {publicAccessMixin} from "@/mixins/common";

const urlFor = function (name, context) {
  return context.getPublicImg(`file_types/${name}.svg`)
};

export const FILES = Object.freeze({
  fileIconFor(mimetype, context) {
    switch (mimetype) {
      case 'application/msword':
      case 'application/vnd.openxmlformats':
      case 'officedocument.wordprocessingml.document':
      case 'application/vnd.ms-fontobject':
      case 'application/epub+zip':
        return urlFor('word', context);
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/vnd.ms-excel.sheet.binary.macroEnabled.12':
      case 'application/vnd.ms-excel':
      case 'text/csv':
      case 'application/vnd.ms-excel.sheet.macroEnabled.12':
        return urlFor('excel', context);
      case 'ppt':
      case 'pptx':
        return urlFor('powerpoint', context);
      case 'application/pdf':
        return urlFor('pdf', context);
      case 'xml':
        return urlFor('xml', context);
      case 'sig':
      case 'sign':
      case 'pkcs7':
        return urlFor('medal', context);
      case 'text/plain':
      case 'js':
      case 'json':
      case 'py':
        return urlFor('text', context);
    }
    if (mimetype.startsWith('image/')) {
      return urlFor('picture', context);
    }
    return urlFor('file', context);
  }
});

export default {
  name: "FileCardImage",
  mixins: [
    publicAccessMixin
  ],
  props: {
    noBg: {
      type: Boolean,
      default: false
    },
    size: {
      type: [Number, String],
      default: 80
    },
    mimetype: {
      type: String,
      default: null,
    },
    src: {
      type: String,
      default: null,
    }
  },
  computed: {
    isImage: function () {
      // return this.$utils.isImageExt(this.ext);
      return false
    },
    source: function () {
      if (this.isImage)
        return this.src || this.getIcon(this.mimetype)
      else return this.getIcon(this.mimetype)
    }
  },
  data() {
    return {}
  },
  methods: {
    getIcon: function (mimetype) {
      return FILES.fileIconFor(mimetype, this)
    }
  }
}
</script>

<style scoped>

</style>
