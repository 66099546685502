<template>
  <main-layout-template
      :drawer-menu="drawerMenu"
  >
    <template v-slot:logo-down>
      <div class="px-5 caption">
        Staff panel
      </div>
    </template>
    <template v-slot:main>
      <router-view></router-view>
    </template>
  </main-layout-template>
</template>

<script>
import {CONSTS} from "@/utils/consts";
import MainLayoutTemplate from "@/components/common/layouts/MainLayoutTemplate";

export default {
  name: "MainLayout",
  components: {MainLayoutTemplate},
  computed: {
    drawerMenu: function () {
      return [
        ...CONSTS.staffMenu,
        ...CONSTS.dashCountsDataMapping
      ].map(item => ({
        key: item.data_key,
        label: this.$t(`staffs.menu.${item.data_key}`),
        color: item.color,
        icon: item.icon,
        to: item.to
      }))
    }
  },
  methods: {
    onPaymentError(data) {
      this.$notifications.notifyPaymentApproveError(this, data.payment, data.message)
    },
    onPaymentDone(data) {
      this.$notifications.notifyPaymentApproveSuccess(this, data.payment)
    },
    // eslint-disable-next-line no-unused-vars
    onPaymentProcessDone(data) {
      // console.log(data)
      this.$notifications.notifyPaymentProcessDone(this)
    }
  },
  created() {
    this.$bus?.$on('ws-transfer_payment_error', this.onPaymentError)
    this.$bus?.$on('ws-income_payment_process_done', this.onPaymentProcessDone)
    this.$bus?.$on('ws-transfer_payment_done', this.onPaymentDone)
  },
  destroyed() {
    this.$bus?.$off('ws-transfer_payment_error', this.onPaymentError)
    this.$bus?.$off('ws-income_payment_process_done', this.onPaymentProcessDone)
    this.$bus?.$off('ws-transfer_payment_done', this.onPaymentDone)
  }
}
</script>

<style scoped>
</style>