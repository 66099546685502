<template>
  <page-wrapper :title="pageTitle">
    <v-expansion-panels accordion>
      <!--          class="transparent"-->
      <v-expansion-panel
          v-for="item in prefItems"
          :key="item.key"
      >
        <v-expansion-panel-header class="text-h6">
          <v-icon left style="max-width: 36px">{{ item.icon }}</v-icon>
          {{ $t(`staffs.prefs.${item.key}.title`) }}
        </v-expansion-panel-header>
        <v-expansion-panel-content color="transparent">
          <component
              :is="item.component"
              :title="$t(`staffs.prefs.${item.key}.title`)"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </page-wrapper>
</template>

<script>
import PageWrapper from "@/components/common/widgets/PageWrapper";
import {pageTitleMixin} from "@/mixins/common";
import {CONSTS} from "@/utils/consts";

export default {
  name: "AppPreferencesPage",
  components: {PageWrapper},
  mixins: [
    pageTitleMixin
  ],
  computed: {
    pageTitle: function () {
      return this.$t('staffs.menu.app_preferences')
    },
    prefItems: function () {
      return CONSTS.staffPrefItems
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>