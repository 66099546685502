<template>
  <page-wrapper ref="scrollPageRoot">
    <v-card>
      <v-card-title>
        {{ pageTitle }}
      </v-card-title>
      <v-card-text>
        <card-list-view-template
            full-width
            :get-data-url="queryFunc"
            @data-loaded="scrollToTop"
        >
          <template v-slot:default="{item}">
            <v-card outlined>
              <v-card-subtitle>
                {{ item.created_at|formatDateTime }}
              </v-card-subtitle>
              <v-card-text>
                <div class="caption">{{$t('staffs.company.signed_reg_document')}}</div>
                <gen-file-view-field
                    :value="item.signed_reg_document"
                />
                <div class="caption">{{$t('fullRegGroups.set_of_docs')}}</div>
                <v-row dense align="stretch">
                  <v-col
                      :sm="SM" :md="MD" :lg="LG"
                      v-for="(doc, ind) in item.docs"
                      :key="`doc__${ind}_${doc.id}`"
                  >
                    <gen-file-view-field
                        :doc-type-labels="docTypesLabels"
                        :value="doc"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </card-list-view-template>
      </v-card-text>
    </v-card>
  </page-wrapper>
</template>

<script>
import PageWrapper from "@/components/common/widgets/PageWrapper";
import {autoPageScrollMixin, flexSizeMixin} from "@/mixins/common";
import CardListViewTemplate from "@/components/common/forms/helpers/CardListViewTemplate";
import endpoints from "@/utils/web/endpoints";
import GenFileViewField from "@/components/common/forms/view_fields/GenFileViewField";
import {CONSTS} from "@/utils/consts";

export default {
  name: "CompanyEditInfoHistoryPage",
  // eslint-disable-next-line vue/no-unused-components
  components: {GenFileViewField, CardListViewTemplate, PageWrapper},
  mixins: [
    autoPageScrollMixin,
    flexSizeMixin,
  ],
  computed: {
    pageTitle: function () {
      return this.$t('staffs.company.counters.edit_count')
    },
    docTypesLabels: function () {
      return CONSTS.companyRegDocTypes.reduce((previousValue, currentValue) => {
        previousValue[currentValue] = this.$t(`setOfDocsTypes.${currentValue}`)
        return previousValue
      }, {})
    }
  },
  data() {
    return {
      queryFunc: (limit, offset, sort) => endpoints.withQuery(
          endpoints.COMPANIES().STAFF_HISTORY(this.$route.params.company_id),
          {
            limit: limit,
            offset: offset,
            sort: sort
          }
      ),
    }
  }
}
</script>

<style scoped>

</style>