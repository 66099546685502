<template>
  <v-card class="dash-counter-card hoverable" :to="to">
    <v-card-title class="pb-0">
      {{ label }}
    </v-card-title>
    <div class="d-flex align-start px-3">
      <v-fade-transition mode="out-in" v-if="!noCount">
        <div class="text-h4" v-if="hasCount">
          {{ count }}
        </div>
        <v-skeleton-loader
            v-else
            class="no-margin"
            type="card-heading"
            height="40"
            width="200"/>
      </v-fade-transition>
      <v-spacer></v-spacer>
      <div class="mt-3" style="height: 60px; position: relative">
        <v-icon :color="color" style="position: absolute; right: 8px; top: -46px">
          {{ icon }}
        </v-icon>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "DashCounterView",
  props: {
    label: {
      type: String,
      default: undefined
    },
    color: {
      type: String,
      default: undefined
    },
    icon: {
      type: String,
      default: undefined
    },
    count: {
      type: Number,
      default: undefined
    },
    noCount: {
      type: Boolean,
      default: false
    },
    to: {
      type: [String, Object],
      default: undefined
    }
  },
  computed: {
    hasCount: function () {
      return this.count !== undefined
    }
  }
}
</script>

<style scoped>

</style>