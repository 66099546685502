import {FieldType} from "@/forms/fieldTypes";
import RULES from "@/plugins/rules";

// eslint-disable-next-line no-unused-vars
export function common(vm, modelVar = 'input') {
  let rules = RULES(vm)
  return [
    {
      label: vm.$t('fullRegFields.phone'),
      key: 'phone',
      type: FieldType.TEXT,
      rules: [
        rules.required(),
        rules.counterMax(20)
      ]
    },
    {
      label: vm.$t('fullRegFields.email'),
      key: 'email',
      type: FieldType.TEXT,
      rules: [
        rules.required(),
        rules.counterMaxEqual(64),
        rules.email(),
      ]
    },
  ]
}

export function companyForm(vm, modelVar = 'input') {
  let rules = RULES(vm)
  return {
    form: [
      {
        key: '_',
        form: [
          {
            label: vm.$t('fullRegFields.company_name'),
            key: 'name',
            type: FieldType.TEXT,
            rules: [
              rules.counterMaxEqual(64),
              rules.required()
            ]
          },
          ...common(vm, modelVar)
        ]
      }
    ]
  }
}

export function personForm(vm, modelVar = 'input') {
  let rules = RULES(vm)
  return {
    form: [
      {
        key: '_',
        form: [
          {
            label: vm.$t('fullRegFields.first_name'),
            key: 'first_name',
            type: FieldType.TEXT,
            rules: [
              rules.counterMaxEqual(64),
              rules.required()
            ]
          },
          {
            label: vm.$t('fullRegFields.last_name'),
            key: 'last_name',
            type: FieldType.TEXT,
            rules: [
              rules.counterMaxEqual(64),
              rules.required()
            ]
          },
          ...common(vm, modelVar)
        ]
      }
    ]
  }
}

export function personInfoForm(vm) {
  let rules = RULES(vm)
  return {
    form: [
      {
        key: '_',
        form: [
          {
            label: vm.$t('fullRegFields.address'),
            key: 'reg_address_object',
            type: FieldType.ADDRESS,
            rules: [
              rules.required()
            ]
          },
          {
            label: vm.$t('fullRegFields.dob'),
            key: 'dob',
            type: FieldType.DATE,
            rules: [
              rules.required()
            ]
          }
        ]
      }
    ]
  }
}