<template>
  <page-wrapper>
    <v-row v-if="loadingStatus$.loaded" dense class="pb-3">
      <v-col sm="12" md="12" lg="8">
        <v-card class="animated fadeIn">
          <v-card-title>
            {{ personName }}
          </v-card-title>
          <v-card-subtitle>
            <i>ID: {{ personData.id }}</i>
            <clipboard-copy-button class="ml-3"
                                   :value="personData.id"
                                   icon small color="warning"/>
            <!--            <v-btn class="ml-3" icon color="warning" small>-->
            <!--              <v-icon size="16">content_copy</v-icon>-->
            <!--            </v-btn>-->
          </v-card-subtitle>
          <v-card-text>
            <change-verification-status-view
                v-model="personData.verification_status"
                class="mb-3"
                :user-id="personData.id"
            />
            <div>
              <div
                  v-for="item in regStatusCheckList" :key="`rscl__${item.key}`"
                  class="d-flex align-center"
              >
                <v-icon left color="success" v-if="personData[item.key]">
                  ri-check-line
                </v-icon>
                <v-icon left color="warning" v-else>
                  ri-close-line
                </v-icon>
                <div>{{ item.label }}</div>
              </div>
            </div>
            <v-card outlined v-if="personData.sumsub_data" class="mt-3">
              <v-card-subtitle class="d-flex align-center">
                <div>
                  Person Sumsub info
                </div>
                <v-spacer/>
                <v-btn :href="personData.sumsub_data.view_url"
                       color="primary" text
                       target="_blank">
                  View on Sumsub
                  <v-icon right>
                    ri-external-link-line
                  </v-icon>
                </v-btn>
              </v-card-subtitle>
              <v-simple-table>
                <template>
                  <tbody>
                  <tr v-for="item in sumsubSimpleDisplayFields" :key="`ss_f__${item.key}`">
                    <th>{{ item.label }}</th>
                    <td style="text-align: right">{{ personData.sumsub_data[item.key] }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t("address.country") }}</th>
                    <td style="text-align: right">
                      <div class="d-flex align-center justify-end">
                        <v-img height="22" contain width="22" max-width="22" class="mr-3"
                               :src="getFlagSrc(personData.sumsub_data.country.iso2)"></v-img>
                        <span>{{ personData.sumsub_data.country.name }}</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t("staffs.sumsub.review_date") }}</th>
                    <td style="text-align: right">{{ personData.sumsub_data.review_date|formatDateTime }}</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
            <company-item-staff-actions-component class="mt-6"
                                                  @action-call="onActionCall"
                                                  @action-done="onActionDone"
                                                  @action-reload="onActionReload"
                                                  :data="personData"
                                                  :actions="actions"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="12" md="12" lg="4">
        <dash-counter-view class="mb-2 animated delayed-appear fadeIn"
                           v-for="item in countsData" :key="`company_cc__${item.key}`"
                           v-bind="item"
        />
      </v-col>
    </v-row>

    <v-fade-transition mode="out-in">
      <router-view></router-view>
    </v-fade-transition>
  </page-wrapper>
</template>

<script>
import PageWrapper from "@/components/common/widgets/PageWrapper.vue";
import {loadingMixin} from "@/mixins/common";
import ClipboardCopyButton from "@/components/common/widgets/ClipboardCopyButton.vue";
import endpoints from "@/utils/web/endpoints";
import {getFlagSrc} from "@/forms/fieldHelpers/phoneNumberFieldUtils";
import CompanyItemStaffActionsComponent from "@/components/staff/widgets/company/CompanyItemStaffActionsComponent.vue";
import DashCounterView from "@/components/staff/widgets/dash/DashCounterView.vue";
import ChangeVerificationStatusView from "@/components/staff/widgets/ChangeVerificationStatusView.vue";

export default {
  name: "PersonFullInfoPage",
  components: {
    ChangeVerificationStatusView,
    DashCounterView, CompanyItemStaffActionsComponent, ClipboardCopyButton, PageWrapper
  },
  mixins: [
    loadingMixin
  ],
  computed: {
    countsData: function () {
      return [
        {
          key: "transactions_count",
          label: this.$t(`staffs.company.counters.transactions_count`),
          // noCount: true,
          count: this.personData?.transactions_count,
          icon: "ri-share-circle-line",
          color: "primary",
          to: {name: 'staff-person-transactions-page'}
        },
        {
          key: "edit_info",
          label: this.$t(`staffs.company.counters.edit_info`),
          noCount: true,
          icon: "ri-edit-line",
          color: "red",
          to: {name: 'staff-person-edit-info-page'}
        },
      ]
    },
    actions: function () {
      return [
        {
          key: 'activate',
          label: this.$t('staffs.company.actions.activate'),
          icon: 'ri-checkbox-circle-line',
          color: 'primary darken-2',
          func: this.activatePerson,
          reloadAfterAction: true,
          disabled: (data) => data.is_active,
          hint: ''
        },
        {
          key: 'deactivate',
          label: this.$t('staffs.company.actions.deactivate'),
          icon: 'ri-checkbox-blank-circle-line',
          color: 'error',
          func: this.deactivatePerson,
          reloadAfterAction: true,
          disabled: (data) => !data.is_active,
          hint: ''
        },
        {
          key: 'send_email',
          label: this.$t('staffs.company.actions.send_email'),
          icon: 'ri-mail-send-line',
          color: 'purple',
          disabled: true,
          // reloadAfterAction: true,
          hint: ''
        },
      ]
    },
    sumsubSimpleDisplayFields: function () {
      return [
        {
          key: 'applicant_id',
          label: this.$t("staffs.sumsub.applicant_id"),
        },
        {
          key: 'first_name',
          label: this.$t("fullRegFields.first_name"),
        },
        {
          key: 'last_name',
          label: this.$t("fullRegFields.last_name"),
        },
        {
          key: 'middle_name',
          label: this.$t("fullRegFields.middle_name"),
        },
        {
          key: 'dob',
          label: this.$t("fullRegFields.dob"),
        },
        {
          key: 'review_result',
          label: this.$t("staffs.sumsub.review_result"),
        },
      ]
    },
    regStatusCheckList: function () {
      return [
        //   is_sumsub_approved
        {
          key: 'full_reg_filled',
          label: this.$t('staffs.person.full_reg_filled')
        },
        {
          key: 'is_sumsub_approved',
          label: this.$t('staffs.person.sumsub_verification')
        },
      ]
    },
    personName: function () {
      return `${this.personData.first_name} ${this.personData.last_name}`
    }
  },
  data() {
    return {
      personData: {}
    }
  },
  methods: {
    setNewStatus(ctx, field, value) {
      return ctx.$http.post(
          endpoints.PERSONS().STAFF_STATUS_SET(
              this.personData.id
          ),
          {
            [field]: value
          },
          {
            changeState: true
          }
      )
    },
    activatePerson(ctx) {
      return this.setNewStatus(ctx, 'is_active', true)
    },
    deactivatePerson(ctx) {
      return this.setNewStatus(ctx, 'is_active', false)
    },
    // eslint-disable-next-line no-unused-vars
    onActionCall(action) {
      // console.log('call', action)
    },
    onActionDone(action, data) {
      this.personData = {
        ...this.personData,
        ...data
      }
      // console.log('done', action, data)
    },
    onActionReload() {
      console.log('reload')
    },
    getFlagSrc,
    async getData() {
      let resp = await this.$http.get(
          endpoints.PERSONS().STAFF_INFO(this.$route.params.person_id),
          {
            changeState: true,
            rootProgress: true,
          }
      )
      this.personData = resp
    }
  },
  created() {
    this.getData()
  }
}
</script>

<style scoped>

</style>