<template>
  <v-row>
    <v-col :sm="SM" :md="MD" :lg="LG"
           class="animated delayed-appear fadeIn"
           v-for="(action, index) in actions"
           :key="`action__${index}`"
    >
      <company-item-staff-action-item-component
          @call="$emit('action-call', action.key)"
          @done="$emit('action-done', action.key, $event)"
          @reload="$emit('action-reload', action.key)"
          :action="action"
          :data="data"
      />
    </v-col>
  </v-row>
</template>

<script>
import {flexSizeMixin} from "@/mixins/common";
import CompanyItemStaffActionItemComponent
  from "@/components/staff/widgets/company/CompanyItemStaffActionItemComponent";

export default {
  name: "CompanyItemStaffActionsComponent",
  components: {CompanyItemStaffActionItemComponent},
  mixins: [
    flexSizeMixin
  ],
  props: {
    lg: {
      default: 6,
    },
    actions: {
      type: Array,
      default: () => []
    },
    data: {
      type: Object
    }
  }
}
</script>

<style scoped>

</style>