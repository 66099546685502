<template>
  <page-wrapper :title="pageTitle">
    <dash-map-counters-view :labels-map="countryCounterLabels"/>
    <dash-general-counters-view class="mt-3"/>
  </page-wrapper>
</template>

<script>
// import PageWrapper from "@/components/common/widgets/PageWrapper";
import DashGeneralCountersView from "@/components/staff/widgets/dash/DashGeneralCountersView";
import {pageTitleMixin} from "@/mixins/common";
import PageWrapper from "@/components/common/widgets/PageWrapper";
import DashMapCountersView from "@/components/staff/widgets/dash/DashMapCountersView.vue";

export default {
  name: "DashboardPage",
  components: {DashMapCountersView, PageWrapper, DashGeneralCountersView},
  mixins: [
    pageTitleMixin
  ],
  computed: {
    countryCounterLabels: function () {
      return {
        persons: {
          label: this.$t("staffs.menu.persons"),
          icon: 'ri-user-line'
        },
        companies: {
          label: this.$t("staffs.menu.companies"),
          icon: 'ri-group-line'
        },
      }
    },
    pageTitle: function () {
      return this.$t('staffs.menu.dash')
    }
  },
}
</script>

<style scoped>

</style>