<template>
  <page-wrapper ref="scrollPageRoot">
    <v-card>
      <v-card-title>
        {{ pageTitle }}
      </v-card-title>
      <v-card-text>
        <card-list-view-template
            @data-loaded="scrollToTop"
            :get-data-url="queryFunc"
        >
          <template v-slot:default="{item}">
            <gen-file-view-field
                :value="item"
            />
          </template>
        </card-list-view-template>
      </v-card-text>
    </v-card>
  </page-wrapper>
</template>

<script>
import PageWrapper from "@/components/common/widgets/PageWrapper";
import {autoPageScrollMixin} from "@/mixins/common";
import CardListViewTemplate from "@/components/common/forms/helpers/CardListViewTemplate";
import endpoints from "@/utils/web/endpoints";
import GenFileViewField from "@/components/common/forms/view_fields/GenFileViewField";

export default {
  name: "CompanyUploadedFilesPage",
  components: {GenFileViewField, CardListViewTemplate, PageWrapper},
  mixins: [
    autoPageScrollMixin,
  ],
  computed: {
    pageTitle: function () {
      return this.$t('staffs.company.counters.uploaded_doc_count')
    },
  },
  data() {
    return {
      queryFunc: (limit, offset, sort) => endpoints.withQuery(
          endpoints.COMPANIES().STAFF_FILES(this.$route.params.company_id),
          {
            limit: limit,
            offset: offset,
            sort: sort
          }
      ),
    }
  },
  methods: {

  }
}
</script>

<style scoped>

</style>