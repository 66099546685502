<template>
  <page-wrapper ref="scrollPageRoot" style="min-height: calc(100vh - 24px)">
    <v-card v-if="loadingStatus$.loaded">
      <v-card-title class="d-flex align-center">
        <div>{{ pageTitle }}</div>
        <v-spacer/>
        <v-btn color="warning" elevation="0" @click="saveData">
          <v-icon left>ri-save-line</v-icon>
          {{ $t('userActions.save') }}
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-tabs
            v-if="input"
            ref="tabsHolder"
            v-model="tab"
            bg-color="primary"
        >
          <v-tab :value="0">
            {{ $t("fullRegSteps.general_info") }}
          </v-tab>
          <v-tab :value="1" v-if="input.person_info">
            {{ $t("fullRegSteps.person_info") }}
          </v-tab>
        </v-tabs>
      </v-card-text>
    </v-card>
    <v-window v-model="tab" v-if="input">
      <v-window-item :value="0">
        <generic-form :form="generalInfoForm" v-if="input.user"
                      class="pt-6"
                      v-model="input.user"/>
      </v-window-item>
      <v-window-item :value="1">
        <generic-form :form="personInfoForm" v-if="input.person_info"
                      class="pt-6"
                      v-model="input.person_info"/>
      </v-window-item>
    </v-window>
  </page-wrapper>
</template>

<script>
import PageWrapper from "@/components/common/widgets/PageWrapper";
import {autoPageScrollMixin, loadingMixin} from "@/mixins/common";
import GenericForm from "@/components/common/forms/GenericForm.vue";
import endpoints from "@/utils/web/endpoints";
import {personForm, personInfoForm} from "@/forms/complete/general_info_form";
import {prepareFormData} from "@/utils/forms";
// import goTo from "vuetify/lib/services/goto";

export default {
  name: "PersonFullInfoEditPage",
  components: {GenericForm, PageWrapper},
  mixins: [
    autoPageScrollMixin,
    loadingMixin
  ],
  computed: {
    personId: function () {
      return this.$route.params.person_id
    },
    generalInfoForm: function () {
      return personForm(this)
    },
    personInfoForm: function () {
      return personInfoForm(this)
    },
    pageTitle: function () {
      return this.$t('staffs.company.counters.edit_info')
    },
  },
  data() {
    return {
      tab: 0,
      input: undefined,
      validSet: undefined,
      fullInfoFilled: false
    }
  },
  methods: {
    getData() {
      this.$http.get(
          endpoints.PERSONS().STAFF_INFO_FOR_EDIT(this.personId),
          {changeState: true}
      ).then(resp => {
        // console.log("C DATA", resp)
        // this.fullInfoFilled = resp.full_info_filled
        this.input = resp
      })
    },
    saveData() {
      let generalInfo = prepareFormData(this.generalInfoForm.form, this.input.user)
      let personalInfo = this.input.person_info ? prepareFormData(this.personInfoForm.form, this.input.person_info) : undefined
      this.$http.post(
          endpoints.PERSONS().STAFF_INFO_FOR_EDIT_ACCEPT(this.personId),
          {
            user: generalInfo,
            person_info: personalInfo
          }
      ).then(() => {
        this.$notify({
          group: 'success',
          text: this.$t('common.changes_saved_successfully')
        })
        // console.log("C DATA", resp)
        // this.input = resp
      })
    }
  },
  watch: {},
  created() {
    // this.input = initModelSetByFormList(this.stepItems)
    // this.validSet = initValidSetByFormList(this.stepItems)
    this.getData()
  }
}
</script>

<style scoped>

</style>