<template>
  <div>
    <v-btn-toggle
        v-model="activeTab"
        mandatory tile group
        color="primary"
    >
      <!--      <v-tabs-slider color="yellow"></v-tabs-slider>-->

      <v-btn
          class="mx-0"
          v-for="tab in tabs"
          :key="`th__${tab.key}`"
      >
        <v-icon left v-if="tab.icon">
          {{ tab.icon }}
        </v-icon>
        {{ tab.label }}
      </v-btn>
    </v-btn-toggle>
    <v-tabs-items v-model="activeTab">
      <v-tab-item
          v-for="tab in tabs"
          :key="`tw__${tab.key}`"
      >
        <country-stat-data-chart :value="chartData[tab.key]"/>
        <!--        <v-card flat>-->
        <!--          <v-card-text>-->
        <!--            {{chartData[tab.key]}}-->
        <!--          </v-card-text>-->
        <!--        </v-card>-->
      </v-tab-item>
    </v-tabs-items>

  </div>
</template>

<script>
import endpoints from "@/utils/web/endpoints";
import CountryStatDataChart from "@/components/common/widgets/CountryStatDataChart.vue";

export default {
  name: "DashMapCountersView",
  components: {CountryStatDataChart},
  props: {
    labelsMap: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      chartData: undefined,
      tabs: undefined,
      activeTab: 0,
    }
  },
  methods: {
    prepareChartData(data) {
      return data.map(item => ({id: item.iso2, value: item.count}))
    },
    getCountryData() {
      this.$http.get(
          endpoints.DASH().COUNTRY_COUNTS
      ).then(resp => {
        let data = {}
        let tabs = []
        Object.keys(resp).forEach(key => {
          data[key] = this.prepareChartData(resp[key])
          tabs.push({
            key: key,
            label: this.labelsMap ? (this.labelsMap[key]?.label || key) : key,
            icon: this.labelsMap && this.labelsMap[key]?.icon,
          })
        })
        this.chartData = data
        this.tabs = tabs
        // console.log(resp)
      })
    }
  },
  created() {
    this.getCountryData()
  }
}
</script>

<style scoped>

</style>