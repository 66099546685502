<template>
  <page-wrapper :title="pageTitle">
    <div class="fh d-flex align-center justify-center">
      <div>
        <div>
          <gen-upload-file-field
              :label="$t('staffs.income.select_file')"
              accept="text/csv"
              v-model="fileToUpload"
          />
        </div>
        <div class="d-flex align-center">
          <v-spacer/>
          <app-button
              class="mt-3"
              @click="acceptFile"
              :disabled="!canContinue"
              icon="ri-arrow-right-line"
              :text="$t('userActions.continue')"
          />
          <v-spacer/>
        </div>
      </div>
    </div>

  </page-wrapper>
</template>

<script>
import PageWrapper from "@/components/common/widgets/PageWrapper.vue";
import {pageTitleMixin} from "@/mixins/common";
import GenUploadFileField from "@/components/common/forms/fields/GenUploadFileField.vue";
import AppButton from "@/components/common/widgets/AppButton.vue";
import endpoints from "@/utils/web/endpoints";

export default {
  name: "UploadIncomePaymentsPage",
  components: {AppButton, GenUploadFileField, PageWrapper},
  mixins: [
    pageTitleMixin
  ],
  computed: {
    pageTitle: function () {
      return this.$t('staffs.menu.income_payments')
    },
    canContinue: function () {
      return !!this.fileToUpload?.id
    }
  },
  data() {
    return {
      fileToUpload: undefined
    }
  },
  methods: {
    acceptFile() {
      this.$http.post(
          endpoints.TRANSFER().STAFF().ACCEPT_INCOME_PAYMENTS_FILE(this.fileToUpload?.id)
      ).then(resp => {
        // console.log(resp)
        this.$router.replace({name: "income_info", params: {income_id: resp.id}})
      })
    }
  }
}
</script>

<style scoped>

</style>