<template>
  <v-card max-width="700" outlined>
    <v-card-text>
      <table v-if="visibleFilters?.length" class="fw">
        <tr v-for="(vf, ind) in visibleFilters" :key="`filter__${ind}_${vf.key}`">
          <td style="width: 200px">{{ getFieldLabel(vf) }}</td>
          <td style="width: 12px"></td>
          <!--          <td style="width: 200px">-->
          <td style="max-width: max(100%, 200px)">
            <form-input-element
                hide-labels
                hide-details dense
                v-model="model[vf.key]"
                :field="vf"
            />
          </td>
          <td style="width: 12px">
            <v-btn icon @click="removeFilter(vf)">
              <v-icon>ri-close-line</v-icon>
            </v-btn>
          </td>
        </tr>
      </table>
      <div v-else>
        No filter presented. Press <b>Add</b> to start filtering
      </div>
    </v-card-text>

    <v-card-actions class="flex-wrap">
      <v-menu max-height="300">
        <template v-slot:activator="{on, attrs}">
          <v-btn v-on="on" v-bind="attrs"
                 text color="primary"
                 :disabled="!availableFilters?.length"
          >
            <v-icon left>ri-add-line</v-icon>
            {{ $t('userActions.add') }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
              @click="addFilter(af)"
              v-for="(af, ind) in availableFilters" :key="`af__${ind}_${af.key}`"
          >
            <v-list-item-title>
              {{ getFieldLabel(af) }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-spacer/>
      <v-btn text :disabled="!visibleFilters?.length" @click="resetFilter">
        <v-icon left>ri-restart-line</v-icon>
        {{ $t('userActions.reset') }}
      </v-btn>
      <v-btn color="primary" @click="submitFilter">
        <v-icon left>ri-check-line</v-icon>
        {{ $t('userActions.submit') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {modelMixin} from "@/mixins/common";
import FormInputElement from "@/components/common/forms/FormInputElement.vue";
import {applyRouterQuery} from "@/plugins/router";
import {decodeObjFromUrl, encodeObjToUrl} from "@/helpers/js_utils";

export default {
  name: "TableFilterView",
  components: {FormInputElement},
  mixins: [
    modelMixin('model', Object, () => ({})),
  ],
  computed: {
    usedKeys: function () {
      return Object.keys(this.model || {})
    },
    filterFormList: function () {
      return Object.keys(this.filterForm).map(key => ({
        key,
        ...this.filterForm[key]
      }))
    },
    visibleFilters: function () {
      return this.filterFormList.filter(f => this.usedKeys.includes(f.key))
    },
    availableFilters: function () {
      return this.filterFormList.filter(f => !this.usedKeys.includes(f.key))
    },
  },
  props: {
    filterCount: {
      type: [Number, undefined],
      default: undefined
    },
    useQueryString: {
      type: Boolean,
      default: false
    },
    /**
     * Record<string, {
     *   label?: string,
     *   localeLabel?: string,
     *   key: string,
     *   type: string,
     *   items: {value: string|number, text: string|any}[]
     * }>
     */
    filterForm: {
      type: Object,
    }
  },
  data() {
    return {
      stringFilter: ''
    }
  },
  watch: {
    visibleFilters: {
      deep: true,
      handler(value) {
        this.$emit('update:filterCount', value.length)
      }
    }
  },
  methods: {
    _getQueryFilter() {
      return decodeObjFromUrl(this.$route.query?.filter) || {}
    },
    _setQueryFilter(filter) {
      let queryfilter = this.$route.query?.filter
      let encoded = encodeObjToUrl(filter)
      if (encoded !== queryfilter) {
        return applyRouterQuery(this, {
          filter: encoded
        }, false)
      }
    },
    getFieldLabel(field) {
      if (field.label) return field.label
      if (field.localeLabel) return this.$t(field.localeLabel)
      return field.key || '???'
    },
    addFilter(field) {
      this.$set(this.model, field.key, field.default)
    },
    removeFilter(field) {
      this.$delete(this.model, field.key)
    },
    resetFilter() {
      this.model = {}
    },
    submitFilter() {
      if (this.useQueryString) {
        this._setQueryFilter(this.model)
      }
      this.$emit('submit', this.model)
    }
  },
  created() {
    if (this.useQueryString) {
      this.model = this._getQueryFilter()
      this.$emit('submit', this.model)
    }
    this.$emit('update:filterCount', this.visibleFilters.length)
  }
}
</script>

<style scoped>

</style>