<template>
  <page-wrapper ver-center hor-center>
    <sign-in-view/>
  </page-wrapper>
</template>

<script>
import SignInView from "@/components/staff/forms/views/SignInView";
import PageWrapper from "@/components/common/widgets/PageWrapper";

export default {
  name: "SignInPage",
  components: {PageWrapper, SignInView}
}
</script>

<style scoped>

</style>