<template>
  <gen-view-field-wrapper>
    <span>{{ value }}</span>
  </gen-view-field-wrapper>
</template>

<script>
import {formFieldViewMixin} from "@/mixins/common";
import GenViewFieldWrapper from "@/components/common/forms/view_fields/GenViewFieldWrapper";

export default {
  name: "GenRawViewField",
  components: {GenViewFieldWrapper},
  mixins: [
    formFieldViewMixin
  ]
}
</script>

<style scoped>

</style>