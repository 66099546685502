<template>
  <gen-view-field-wrapper>
    <v-icon v-if="value" color="primary">
      ri-checkbox-circle-line
    </v-icon>
    <v-icon v-else color="warning">
      ri-checkbox-blank-circle-line
    </v-icon>
  </gen-view-field-wrapper>
</template>

<script>
import {formFieldViewMixin} from "@/mixins/common";
import GenViewFieldWrapper from "@/components/common/forms/view_fields/GenViewFieldWrapper";

export default {
  name: "GenBoolViewField",
  components: {GenViewFieldWrapper},
  mixins: [
    formFieldViewMixin
  ]
}
</script>

<style scoped>

</style>