<template>
  <v-card
      class="mx-3" max-width="400" min-width="min(90%, 400px)"
      color="transparent" flat
  >
    <app-logo with-text staff/>
    <!--    <v-card-title class="text-h3">VelurePay <span class="pl-3 font-weight-light">BO</span></v-card-title>-->
    <v-card-subtitle class="text-h4">
      {{ $t('signIn.title') }}
    </v-card-subtitle>
    <v-card-text class="mt-3">
      <v-form @submit.prevent="submitForm">
        <button style="display: none" type="submit"></button>
        <div key="loginInput">
          <form-input-element
              hide-details
              :field="inputFieldBind" v-model="payload.email"/>
          <form-input-element
              class="mt-3"
              hide-details
              :field="passFieldBind" v-model="payload.secret"/>
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <app-button :loading="loadingStatus$.loading"
                  @click="submitForm" icon="ri-login-circle-line">
        {{ $t('userActions.signIn') }}
      </app-button>
      <!--      <v-btn large class="rounded-pill px-3"-->
      <!--             min-width="200" light-->
      <!--             @click="submitForm"-->
      <!--             :loading="loadingStatus$.loading"-->
      <!--      >-->
      <!--        {{ $t('userActions.signIn') }}-->
      <!--      </v-btn>-->
      <v-spacer/>
    </v-card-actions>
  </v-card>
</template>

<script>

import FormInputElement from "@/components/common/forms/FormInputElement";
import {FieldType} from "@/forms/fieldTypes";
import {loadingMixin, rulesMixin} from "@/mixins/common";
import endpoints from "@/utils/web/endpoints";
import {getSignInRedirectRouter} from "@/helpers/router_utils";
import AppLogo from "@/components/common/widgets/AppLogo";
import AppButton from "@/components/common/widgets/AppButton";

export default {
  name: "SignInView",
  components: {AppButton, AppLogo, FormInputElement},
  mixins: [rulesMixin, loadingMixin],
  computed: {
    inputFieldBind: function () {
      return {
        label: this.$t('signIn.email'),
        key: 'login',
        type: FieldType.TEXT,
        rules: [
          this.inputValidationRules.required(),
          this.inputValidationRules.email(),
          this.inputValidationRules.counterMaxEqual(64)
        ]
      }
    },
    passFieldBind: function () {
      return {
        label: this.$t('signIn.password'),
        key: 'password',
        type: FieldType.TEXT,
        password: true,
        rules: [
          this.inputValidationRules.required(),
          this.inputValidationRules.counterMaxEqual(64)
        ]
      }
    }
  },
  data() {
    return {
      payload: {
        email: '',
        secret: '',
        cls: 'staffuser'
      },
    }
  },
  methods: {
    submitForm() {
      this.signInUser()
    },
    signInUser() {
      this.$http.post(
          endpoints.USERS().SIGNIN,
          this.payload,
          {
            changeState: true,
            anonymous: true
          }
      ).then(respData => {
        if (respData.user_cls !== "staffuser") {
          this.$notify({
            duration: 10000,
            group: 'info',
            text: this.$t('signIn.wrongAccountType')
          })
          return
        }
        this.$store.commit("setAuthData", respData)
        this.$emit('signin-success')
        let nextRoute = getSignInRedirectRouter(respData)
        if (nextRoute) {
          this.$router.replace(nextRoute)
        }
      })
    }
  },
}
</script>

<style scoped>

</style>