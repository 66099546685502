<template>
  <v-card @click="itemClick"
          outlined :disabled="disabled"
          :loading="loadingStatus$.loading"
  >
    <v-list-item>
      <v-list-item-avatar>
        <v-avatar color="white" size="30">
          <v-icon :color="action.color" size="24">
            {{ action.icon }}
          </v-icon>
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ action.label }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
import {loadingMixin} from "@/mixins/common";

export default {
  name: "CompanyItemStaffActionItemComponent",
  mixins: [
    loadingMixin
  ],
  computed: {
    disabled: function () {
      if (typeof this.action.disabled === 'function')
        return this.action.disabled.call(this, this.data)
      return this.action.disabled
    }
  },
  props: {
    action: {
      type: Object,
    },
    data: {
      type: Object
    }
  },
  methods: {
    itemClick() {
      this.$emit('call')
      if (this.action.func) {
        this.action.func.call(this, this).then(data => {
          this.$emit('done', data)
          if (this.action.reloadAfterAction) {
            this.$emit('reload')
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>