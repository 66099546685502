<template>
  <page-wrapper :title="pageTitle">
    <members-table-view
        @row-click="onRowClick"
        :table-headers="tableHeaders"
        use-search use-filter
        :filter-form="filterForm"
        search-use-query filter-use-query
        :url-func="getDataQuery"
    >
      <template v-slot:prop__verification_status="{value}">
        <user-verification-status-view
            :verification-status="value"
            dense class="fh rounded-sm my-1 px-0"
            alt-color reverse
        />
      </template>
    </members-table-view>
  </page-wrapper>
</template>

<script>
import PageWrapper from "@/components/common/widgets/PageWrapper";
import {pageTitleMixin} from "@/mixins/common";
import {FieldType} from "@/forms/fieldTypes";
import endpoints from "@/utils/web/endpoints";
import MembersTableView from "@/components/staff/forms/views/shared/MembersTableView";
import UserVerificationStatusView from "@/components/common/widgets/UserVerificationStatusView.vue";

export default {
  name: "CompaniesListPage",
  components: {UserVerificationStatusView, MembersTableView, PageWrapper},
  mixins: [
    pageTitleMixin
  ],
  computed: {
    pageTitle: function () {
      return this.$t('staffs.menu.persons')
    },
    filterForm: function () {
      return {
        full_reg_filled: {
          type: FieldType.BOOL,
          localeLabel: 'staffs.company.full_reg_filled'
        },
        is_sumsub_approved: {
          type: FieldType.BOOL,
          localeLabel: 'common.fields.is_sumsub_approved'
        },
        is_staff_approved: {
          type: FieldType.BOOL,
          localeLabel: 'common.fields.is_staff_approved'
        },
        is_active: {
          type: FieldType.BOOL,
          localeLabel: 'common.fields.is_active'
        },
        created_at__lt: {
          type: FieldType.DATETIME,
          label: `${this.$t('staffs.transactions.fields.created_at')} (${this.$t('search.num_lt')})`,
        },
        created_at__gt: {
          type: FieldType.DATETIME,
          label: `${this.$t('staffs.transactions.fields.created_at')} (${this.$t('search.num_gt')})`,
        },
      }
    },
    tableHeaders: function () {
      return {
        first_name: {
          type: FieldType.TEXT,
          localeLabel: 'fullRegFields.first_name'
        },
        last_name: {
          type: FieldType.TEXT,
          localeLabel: 'fullRegFields.last_name'
        },
        email: {
          type: FieldType.EMAIL,
          localeLabel: 'fullRegFields.email'
        },
        phone: {
          type: FieldType.PHONE,
          localeLabel: 'fullRegFields.phone'
        },
        full_reg_filled: {
          type: FieldType.BOOL,
          localeLabel: 'staffs.company.full_reg_filled'
        },
        is_sumsub_approved: {
          type: FieldType.BOOL,
          localeLabel: 'common.fields.is_sumsub_approved'
        },
        is_staff_approved: {
          type: FieldType.BOOL,
          localeLabel: 'common.fields.is_staff_approved'
        },
        is_active: {
          type: FieldType.BOOL,
          localeLabel: 'common.fields.is_active'
        },
        verification_status: {
          type: FieldType.TEXT,
          localeLabel: 'common.fields.verification_status'
        },
        created_at: {
          type: FieldType.DATETIME,
          localeLabel: 'common.fields.created_at'
        }
      }
    },
  },
  methods: {
    getDataQuery(limit, offset, order) {
      return endpoints.withQuery(
          endpoints.PERSONS().STAFF_LIST,
          {
            limit: limit,
            offset: offset,
            sort: order
          }
      )
    },
    onRowClick(item) {
      this.$router.push({
        name: 'staff-person-info-page',
        params: {'person_id': item.id}
      })
    }
  }
}
</script>

<style scoped>

</style>