<template>
  <page-wrapper>
    <v-row v-if="loadingStatus$.loaded" dense class="pb-3">
      <v-col sm="12" md="12" lg="8">
        <v-card class="animated fadeIn">
          <v-card-title>
            {{ companyData.name }}
          </v-card-title>
          <v-card-subtitle>
            <i>ID: {{ companyData.id }}</i>
            <clipboard-copy-button class="ml-3"
                                   :value="companyData.id"
                                   icon small color="warning"/>
            <!--            <v-btn class="ml-3" icon color="warning" small>-->
            <!--              <v-icon size="16">content_copy</v-icon>-->
            <!--            </v-btn>-->
          </v-card-subtitle>
          <v-card-text>
            <company-registration-progress-staff-view :company-info="companyData"/>
            <change-verification-status-view
                v-model="companyData.verification_status"
                class="my-3"
                :user-id="companyData.id"
                is-company
            />
            <v-row align="stretch" class="mt-6">
              <v-col v-for="fileItem in documentsForView"
                     class="d-flex flex-column"
                     :key="`file__${fileItem.key}`">
                <div class="pb-1">{{ $t(`staffs.company.${fileItem.key}`) }}</div>
                <div class="fh">
                  <gen-file-view-field
                      :value="companyData[fileItem.key]"
                      :color="fileItem.color"
                  />
                </div>
              </v-col>
            </v-row>
            <company-item-staff-actions-component
                @action-call="onActionCall"
                @action-done="onActionDone"
                @action-reload="onActionReload"
                :data="companyData"
                :actions="actions"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="12" md="12" lg="4">
        <dash-counter-view class="mb-2 animated delayed-appear fadeIn"
                           v-for="item in countsData" :key="`company_cc__${item.key}`"
                           v-bind="item"
        />
      </v-col>
    </v-row>

    <v-fade-transition mode="out-in">
      <router-view @data-updated="onDataUpdated"></router-view>
    </v-fade-transition>
  </page-wrapper>
</template>

<script>
import PageWrapper from "@/components/common/widgets/PageWrapper";
import {loadingMixin, pageTitleMixin} from "@/mixins/common";
import endpoints from "@/utils/web/endpoints";
import {CONSTS} from "@/utils/consts";
import DashCounterView from "@/components/staff/widgets/dash/DashCounterView";
import CompanyRegistrationProgressStaffView
  from "@/components/staff/widgets/company/CompanyRegistrationProgressStaffView";
import GenFileViewField from "@/components/common/forms/view_fields/GenFileViewField";
import CompanyItemStaffActionsComponent from "@/components/staff/widgets/company/CompanyItemStaffActionsComponent";
import ClipboardCopyButton from "@/components/common/widgets/ClipboardCopyButton";
import ChangeVerificationStatusView from "@/components/staff/widgets/ChangeVerificationStatusView.vue";

export default {
  name: "CompanyFullInfoPage",
  components: {
    ChangeVerificationStatusView,
    ClipboardCopyButton,
    CompanyItemStaffActionsComponent,
    GenFileViewField, CompanyRegistrationProgressStaffView, DashCounterView, PageWrapper
  },
  mixins: [
    loadingMixin,
    pageTitleMixin
  ],
  computed: {
    pageTitle: function () {
      return this.companyData.name
    },
    countsData: function () {
      return CONSTS.companyStaffMenuCards.map(item => ({
        key: item.data_key,
        label: this.$t(`staffs.company.counters.${item.data_key}`),
        color: item.color,
        icon: item.icon,
        to: item.to,
        noCount: item.noCount,
        count: this.companyData[item.data_key],
      }))
    },
    actions: function () {
      return [
        {
          key: 'activate',
          label: this.$t('staffs.company.actions.activate'),
          icon: 'ri-checkbox-circle-line',
          color: 'primary darken-2',
          func: this.activateCompany,
          reloadAfterAction: true,
          disabled: (data) => data.is_active,
          hint: ''
        },
        {
          key: 'deactivate',
          label: this.$t('staffs.company.actions.deactivate'),
          icon: 'ri-checkbox-blank-circle-line',
          color: 'error',
          func: this.deactivateCompany,
          reloadAfterAction: true,
          disabled: (data) => !data.is_active,
          hint: ''
        },
        {
          key: 'approve',
          label: this.$t('staffs.company.actions.approve'),
          icon: 'ri-shield-check-line',
          color: 'info',
          func: this.approveCompany,
          reloadAfterAction: true,
          disabled: (data) => data.is_staff_approved,
          hint: ''
        },
        {
          key: 'send_email',
          label: this.$t('staffs.company.actions.send_email'),
          icon: 'ri-mail-send-line',
          color: 'purple',
          disabled: true,
          // reloadAfterAction: true,
          hint: ''
        },
      ]
    }
  },
  data() {
    return {
      companyData: {},
      documentsForView: [
        {
          key: 'reg_document',
          cond: 'full_reg_doc_generated',
          color: 'primary'
        },
        {
          key: 'signed_reg_document',
          cond: 'full_reg_doc_signed',
          color: 'warning'
        },
      ]
    }
  },
  methods: {
    onDataUpdated() {
      console.log("DATA UPDATED")
      this.getData()
    },
    setNewStatus(ctx, field, value) {
      return ctx.$http.post(
          endpoints.COMPANIES().STAFF_STATUS_SET(
              this.companyData.id
          ),
          {
            [field]: value
          },
          {
            changeState: true
          }
      )
    },
    activateCompany(ctx) {
      return this.setNewStatus(ctx, 'is_active', true)
    },
    deactivateCompany(ctx) {
      return this.setNewStatus(ctx, 'is_active', false)
    },
    approveCompany(ctx) {
      return this.setNewStatus(ctx, 'is_staff_approved', true)
    },
    // eslint-disable-next-line no-unused-vars
    onActionCall(action) {
      // console.log('call', action)
    },
    onActionDone(action, data) {
      this.companyData = {
        ...this.companyData,
        ...data
      }
      // console.log('done', action, data)
    },
    onActionReload() {
      console.log('reload')
    },
    async getData() {
      let resp = await this.$http.get(
          endpoints.COMPANIES().STAFF_INFO(this.$route.params.company_id),
          {
            changeState: true,
            rootProgress: true,
          }
      )
      this.companyData = resp
    }
  },
  created() {
    this.getData()
  }
}
</script>

<style scoped>

</style>