<template>
  <div>
    <div class="heading">
      {{ $t('staffs.company.registrationProgress') }}
    </div>
    <v-progress-linear
        :value="progressNumber"
        height="10" rounded
        class="my-1" :buffer-value="100">

    </v-progress-linear>
    <div>
      <span class="font-weight-medium">{{ progressLabel }}</span>
      <span v-if="progressLabelNext" class="pl-2" style="opacity: 70%">
        {{ $t('common.next') }}: {{ progressLabelNext }}
      </span>
    </div>
  </div>
</template>

<script>
const progressFields = [
  '#initial_reg_complete',
  'full_reg_filled',
  'full_reg_doc_generated',
  'full_reg_doc_signed',
  'is_staff_approved',
  '#done'
]
export default {
  name: "CompanyRegistrationProgressStaffView",
  computed: {
    progressIndex: function () {
      return this.getCurrentRegistrationProgress()
    },
    progressLabel: function () {
      if (this.progressIndex >= progressFields.length) {
        return this.$t("staffs.company.#done")
      }
      return this.$t(`staffs.company.${progressFields[this.progressIndex]}`)
    },
    progressLabelNext: function () {
      let key = progressFields[this.progressIndex + 1]
      return key ? this.$t(`staffs.company.${key}`) : undefined
    },
    progressNumber: function () {
      return (this.progressIndex + 1) / progressFields.length * 100
    },
  },
  props: {
    companyInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      // progressFields: progressFields,
    }
  },
  methods: {
    getCurrentRegistrationProgress() {
      let progress = 0
      for (let i = 0; i < progressFields.length; i++) {
        let field = progressFields[i]
        if (field.startsWith('#') || this.companyInfo[field]) {
          progress++
        } else break
      }
      return progress
    },
  }
}
</script>

<style scoped>

</style>